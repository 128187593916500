import {connect} from 'react-redux';
import ImageModal from './ImageModal';
import MainActions from './../../../../actions/mainActions';

const mapStateToProps = state => ({
  image_modal: state.main.image_modal,
  client: state.client,
  user: state.user,
  main: state.main
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(MainActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
