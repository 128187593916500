import * as SIDEBAR_TYPES from '../types';

class SidebarActions{
  // Open sidebar
  static open = () => {
    return (dispatch) => {
      return dispatch({
        type: SIDEBAR_TYPES.OPEN_SIDEBAR,
        payload: { isOpen: true }
      });
    }
  }

  // Close sidebar
  static close = () => {
    return (dispatch) => {
      return dispatch({
        type: SIDEBAR_TYPES.CLOSE_SIDEBAR,
        payload: { isOpen: false }
      });
    }
  }
}

export default SidebarActions;