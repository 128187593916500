import React, { useState, useEffect } from "react";
import * as cn from "classnames";

// Components
import AddTagsForm from "../Tags/AddTagsForm";
import Alert from "components/Alert";
import CategoryDropdown from "../CategoryDropdown";

// Services
import ImageService from "services/ImageService";

import ROLE from "utils/enums/role";

const isEmptyString = (str) => {
  return !str || str.length === 0;
};

const UpdateImages = (props) => {
  const [toggleForm, setToggleForm] = useState(
    props.isExternalDA ? false : true
  );
  const [tagsErrors, setTagsErrors] = useState([]);

  const [createErrors, setCreateErrors] = useState([]);
  const [createResponseMessage, setCreateResponseMessage] = useState("");

  const [applyTagErrors, setApplyTagErrors] = useState([]);
  const [applyTagResponseMessage, setApplyTagResponseMessage] = useState("");

  const [tags, setTags] = useState([]);
  let defaultTag = [
    { name: "productList", value: "productList", type: "array" },
  ];

  const [inputList, setInputList] = useState(defaultTag);

  const [isAppending, setIsAppending] = useState(false);

  useEffect(() => {
    getTags();
  }, [props.serviceType]);

  const handleToggleForm = () => {
    setToggleForm(!toggleForm);
  };

  const getTags = async () => {
    const client = props.client;
    const serviceType = props.serviceType;
    if (serviceType && serviceType != "") {
      const response = await ImageService.getTags(
        client,
        serviceType.value.toLowerCase()
      );

      if (response.errors) {
        setTagsErrors(response.errors);
      } else {
        // Nested Object destructuring
        const { fields } = response;
        mapTags(fields);
      }
    }
  };

  const mapTags = (fields) => {
    const _tags = [];

    Object.values(fields)
      .reduce((array, item) => {
        item.key === "editor" &&
        props.user.roles &&
        props.user.roles.some((role) => {
          return role.value === ROLE.DATA_ANALYST;
        })
          ? array.splice(
              array.findIndex((obj) => {
                return obj.key == item.key;
              }),
              1
            )
          : array.push(item);

        return array;
      }, [])
      .filter((item) => {
        if (item.stringValue) {
          _tags.push({
            display: item.key,
            value: item.key,
            type:
              item.stringValue === "string"
                ? "string"
                : item.stringValue === "array"
                ? "array"
                : item.stringValue === "bool"
                ? "bool"
                : undefined,
          });
        } else if (item.numberValue) {
          _tags.push({
            display: item.key,
            value: item.key,
            type: "int",
          });
        } else if (item.booleanValue) {
          _tags.push({
            display: item.key,
            value: item.key,
            type: "bool",
          });
        }
      });

    _tags.sort((a, b) => a.value.localeCompare(b.value));

    setTags(
      [{ value: "", display: "Select tag" }].concat(
        _tags.filter((item) => {
          return props.isExternalDA
            ? item.value === "productList" ||
                item.value === "modelTestType" ||
                item.value === "testIds" ||
                item.value === "trolley"
            : true;
        })
      )
    );
    
  };

  const handleCreate = async (tag) => {
    const response = await ImageService.createTags(
      props.client,
      [
        {
          tag: tag.name,
          type: tag.type,
        },
      ],
      props.serviceType.value.toLowerCase()
    );

    setCreateResponseMessage([]);
    setCreateResponseMessage("");

    if (response.errors) {
      setCreateErrors(response.errors);
    } else {
      if (response.success) {
        setCreateResponseMessage(response.message);
        document.getElementById("createTagForm") &&
          document.getElementById("createTagForm").reset();

        getTags();

        setTimeout(() => {
          setCreateResponseMessage("");
        }, 2000);
      }
    }
  };

  const handleApplyTag = async (e) => {
    e.preventDefault();

    setApplyTagErrors([]);
    setApplyTagResponseMessage("");

    let currentInputs = inputList
      .filter((item) => item.name != "" && item.value != "")
      .map((item) => {
        delete item.type;
        return { key: item.name, value: item.value };
      });

    if (currentInputs.length > 0) {
      let tags = [...currentInputs, { key: "editor", value: props.user.email }];

      if (
        (tags.some((item) => {
          return (
            item.key === "modelTestType" &&
            item.value.toUpperCase() !== "DETECTOR"
          );
        }) &&
          !tags.some((item) => {
            return item.key === "testIds";
          })) ||
        (tags.some((item) => {
          return item.key === "testIds";
        }) &&
          !tags.some((item) => {
            return (
              item.key === "modelTestType" &&
              item.value.toUpperCase() !== "DETECTOR"
            );
          }))
      ) {
        setApplyTagErrors([
          "Validation: modelTestType tag has to been applied with testIds tag",
        ]);
        return;
      } else {
        const response = await ImageService.updateFile(
          props.client,
          tags,
          props.ids,
          props.serviceType.value.toLowerCase(),
          isAppending ? "append" : ""
        );
        if (response.errors !== null) {
          setApplyTagErrors(response.errors);
        } else {
          setApplyTagResponseMessage(response.message);

          getTags();

          await props.refresh();

          setTimeout(() => {
            setApplyTagResponseMessage("");
            setInputList(defaultTag);
          }, 1000);
        }
        setIsAppending(false);
        handleOnClose();
      }
    }
  };

  const handleOnClose = () => {
    props.onClose();
    setInputList(defaultTag);
    setToggleForm(true);
  };

  const handleSelectTag = (e, index) => {
    const { value } = e.target;
    const tag = tags.find((tag) => tag.value === value);

    const list = [...inputList];

    list[index].name = value;
    if(tag !== undefined) {
      list[index].type = tag.type;
    }

    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    let { value } = e.target;

    const list = [...inputList];
    list[index].value = value.trim();

    setInputList(list);
  };

  const handleChangeAppendTo = () => setIsAppending(!isAppending);

  const renderTagValue = (tag, index) => {
    const { client } = props.client;

    if (tag.type === "array") {
      return (
        <CategoryDropdown
          className="image-modal-input"
          placeholder="Search"
          label={"Value"}
          key={tag.name}
          name={tag.name}
          id={tag.name}
          type={"applySelectedTag"}
          item={{}}
          options={[]}
          selected={[]}
          setSelectedTagValue={(value) => {
            const list = [...inputList];
            list[index].value = value.trim();
            setInputList(list);
          }}
          client={client}
        />
      );
    } else if (tag.type === "bool") {
      return (
        <div className="selection-group">
          <div className="single-select">
            <input
              name="single-select-input"
              type="radio"
              value="true"
              id="updateImagesYes"
              onChange={(e) => {
                handleInputChange(e, index);
              }}
            />
            <label htmlFor="updateImagesYes">Yes</label>
            <input
              defaultChecked
              name="single-select-input"
              type="radio"
              value="false"
              id="updateImagesNo"
              onChange={(e) => {
                handleInputChange(e, index);
              }}
            />
            <label htmlFor="updateImagesNo">No</label>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "20px" }}>
          <label className="form-label">Value:</label>
          <input
            type="text"
            name="value"
            id="value"
            value={tag.value || ""}
            onChange={(e) => {
              handleInputChange(e, index);
            }}
          />
        </div>
      );
    }
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    setInputList([...inputList, { name: "", value: "", type: "" }]);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const classes = cn("modal update-images-modal noselect", {
    "is-visible": props.show,
  });

  return (
    <div className={classes}>
      <div className="modal-container">
        <div className="modal-close">
          <button
            id="closeModal"
            onClick={() => {
              handleOnClose();
            }}
          >
            <i className="fe fe-x"></i>
          </button>
        </div>
        <div className="modal-content">
          {!toggleForm && (
            <div>
              <div className="modal-section-title">
                <h4>Create tag:</h4>
                <p className="pull-text-right">
                  <span
                    className="toggle-forms-btn noselect"
                    onClick={() => {
                      handleToggleForm();
                    }}
                  >
                    Choose existing
                  </span>
                </p>
              </div>
              {createErrors.length > 0 && (
                <Alert type={"danger"}>
                  {createErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}
              {createResponseMessage && (
                <Alert type={"success"}>
                  <span>{createResponseMessage}</span>
                </Alert>
              )}
              <AddTagsForm onSubmit={handleCreate} id="addForm" />
            </div>
          )}

          {toggleForm && (
            <div>
              <div className="modal-section-title">
                <h4>Choose tag:</h4>
                {!props.isExternalDA && (
                  <p className="pull-text-right">
                    <span
                      className="toggle-forms-btn noselect"
                      onClick={() => {
                        handleToggleForm();
                      }}
                    >
                      Create tag
                    </span>
                  </p>
                )}
              </div>

              {applyTagErrors.length > 0 && (
                <Alert type={"danger"}>
                  {applyTagErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}
              {applyTagResponseMessage && (
                <Alert type={"success"}>
                  <span>{applyTagResponseMessage}</span>
                </Alert>
              )}

              {tagsErrors.length > 0 && (
                <Alert type={"danger"}>
                  {tagsErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}
              <form autoComplete="off" id="chooseExistingTagForm">
                <div className="form-section">
                  {inputList.map((input, idx) => {
                    return (
                      <div className="input-box" key={idx}>
                        {idx > 0 && (
                          <button
                            className="remove-tag-btn"
                            onClick={(e) => handleRemoveClick(e, idx)}
                          >
                            <i className="fe fe-trash-2"></i>
                          </button>
                        )}
                        <label className="form-label">Name:</label>
                        <select
                          className="select-dropdown"
                          value={input.name}
                          onChange={(e) => handleSelectTag(e, idx)}
                        >
                          {tags.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.display}
                              </option>
                            );
                          })}
                        </select>
                        {!isEmptyString(input.name) &&
                          renderTagValue(input, idx)}
                      </div>
                    );
                  })}
                </div>
                {inputList.length > 0 && (
                  <span
                    className="apply-more-tags-btn noselect"
                    onClick={handleAddInput}
                  >
                    Apply more
                  </span>
                )}
                {inputList.filter((item) => item.type === "array").length >
                  0 && (
                  <div className="flex-box flex-align-items-center append-to-box">
                    <input
                      type="checkbox"
                      name="appendTo"
                      className="select-append-to"
                      checked={isAppending}
                      onChange={() => handleChangeAppendTo()}
                    />
                    <p>Add to exisiting value(s)</p>
                  </div>
                )}
                <div className="form-footer">
                  <button
                    type="submit"
                    onClick={(e) => {
                      handleApplyTag(e);
                    }}
                    className="btn btn-success btn-block"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateImages;
