import { combineReducers } from 'redux';

import UserReducer from './userReducer';
import SidebarReducer from './sidebarReducer';
import MainReducer from './mainReducer';
import ClientReducer from './clientReducer';
import JobReducer from './jobReducer';

export default combineReducers({
  user: UserReducer,
  sidebar: SidebarReducer,
  main: MainReducer,
  client: ClientReducer,
  job: JobReducer
});