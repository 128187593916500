import React, { useRef } from "react";
import Icon from "./../../../../components/Icon";
import imageUrlFormatter from "./../../../../utils/helpers/imageUrlFormatter";
import * as classnames from "classnames";
import { isEmptyString } from "../../../../utils/helpers/string";
import { useAuth0 } from "../../../../context/auth-context";
import useImgAuth from "../../../../utils/helpers/useImgAuth";

const FILE_TYPE = {
  CLUSTERED_CROPS: "clusteredCrops",
  FRAMES: "frames",
};

const ImageItem = ({
  image,
  selected,
  onChange,
  onClick,
  openLightBox,
  imageIndex,
  handleWatchedVideo,
}) => {
  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 2.0;
  };
  const { userToken } = useAuth0();

  const imgUrl = useImgAuth(
    imageUrlFormatter(
      image.imageUrl,
      image.fileType,
      {
        width: 400,
        height: 400,
        fit: "cover",
      },
      userToken
    )
  );

  const videoUrl = useImgAuth(
    imageUrlFormatter(image.videoUrl, "images", {}, userToken)
  );

  const sourceUrl = imageUrlFormatter(image.videoUrl, image.fileType, {
    width: 400,
    height: 400,
    fit: "cover",
  });

  return (
    <div className="image-item">
      <div
        className="flags-section"
        title={
          image.modelTestType && image.testIds
            ? "Test id"
            : image.productList &&
              image.productList.filter((item) => item != "").length > 0
            ? "Product id"
            : ""
        }
      >
        {image.trolley && <span className="flag trolley-flag">Trolley</span>}
        {image.modelTestType && image.testIds && (
          <React.Fragment>
            <span className="flag test-type-flag">Model test type</span>
            {image.testIds.map((value, idx) => (
              <span
                className="flag test-id-flag"
                data-tooltip="Test id"
                key={idx}
              >
                {value.replace(/[\[\]']+/g, "")}
              </span>
            ))}
          </React.Fragment>
        )}

        {image.productList &&
          image.productList.filter((item) => item != "").length > 0 &&
          image.productList.map((value, idx) => (
            <span className="flag product-list-id-flag" key={idx}>
              {value.replace(/[\[\]']+/g, "")}
            </span>
          ))}
      </div>
      <div className="bottom-flags-section">
        {image.cvFilters &&
          image.cvFilters.filter((item) => item !== "").length > 0 &&
          image.cvFilters.map((value, idx) => (
            <span
              className="flag product-list-id-flag optimization-flag"
              key={idx}
            >
              {value.replace(/[\[\]']+/g, "")}
            </span>
          ))}
      </div>

      <label className="image-check">
        <input
          type="checkbox"
          className="image-check-input"
          onChange={(e) => onChange(e, image)}
          checked={!!selected[image._id]}
        />
        {image.imageUrl && (
          <figure
            className={classnames(
              "image-check-figure",
              image.cvFilters.length > 0 ? "detected-image" : ""
            )}
            style={{
              backgroundImage: `
              ${
                image.cvFilters.length > 0
                  ? "" //"linear-gradient(rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1)), "
                  : ""
              }
              
              url('${imgUrl}')`,
              backgroundSize:
                image.fileType === FILE_TYPE.CLUSTERED_CROPS
                  ? "contain"
                  : "cover",
            }}
          ></figure>
        )}
        {image.videoUrl && (
          <figure className="image-check-figure video-item">
            <video
              controls
              poster={videoUrl}
              ref={videoRef}
              onCanPlay={() => setPlayBack()}
              preload="none"
              className="video"
              onClick={(e) => onChange(e, image)}
            >
              <source src={sourceUrl} type="video/mp4" />
            </video>
          </figure>
        )}
      </label>
      <span
        className={classnames(
          "image-action see-current-tags",
          !isEmptyString(image.videoUrl) ? "video-item-position" : ""
        )}
        onClick={() => {
          onClick(image._id);
        }}
      >
        <Icon name="more-horizontal" />
      </span>
      {!isEmptyString(image.videoUrl) && (
        <label className="watched-video" htmlFor={`videoWatched${image._id}`}>
          Watched
          <input
            type="checkbox"
            id={`videoWatched${image._id}`}
            onClick={(e) => handleWatchedVideo(e, image)}
            checked={image.watchedVideo}
          />
          <span className="checkmark" />
        </label>
      )}
      {isEmptyString(image.videoUrl) && (
        <span
          className="image-action see-full-image"
          onClick={() => openLightBox(imageIndex)}
        >
          <Icon name="maximize" />
        </span>
      )}
    </div>
  );
};

export default ImageItem;
