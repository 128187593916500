const SERVICE_TYPE = {
  TRAY: "TRAY",
  SCAN: "SCAN",
};

export const SERVICE_TYPES = [
  {
    display: "check:" + SERVICE_TYPE.TRAY.toLowerCase(),
    value: SERVICE_TYPE.TRAY,
  },
  {
    display: "check:" + SERVICE_TYPE.SCAN.toLowerCase(),
    value: SERVICE_TYPE.SCAN,
  },
];

export const IMAGE_PROPS = {
  PRODUCT_ID: "productID",
  ID: "_id",
  PATH: "path",
  EXTENSION: "extension",
  FILE_TYPE: "fileType",
  MODEL_TEST_TYPE: "modelTestType",
  TEST_IDS: "testIds",
  PRODUCT_LIST: "productList",
  WATCHED_VIDEO: "watchedVideo",
  CV_FILTERS: "cvFilters",
  TROLLEY: "trolley",
};

export const IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;
export const VIDEO_PATH = process.env.REACT_APP_VIDEO_PATH;
export const VIDEO_TOKEN = process.env.REACT_APP_VIDEO_TOKEN;

export const FILE_TYPE = {
  CLUSTERED_CROPS: "clusteredCrops",
  FRAMES: "frames",
};
