import React, { Component } from "react";

import onClickOutside from "react-onclickoutside";

// Css classes
import * as cn from "classnames";

// Utils
import { convertDateTimeToString } from "utils/helpers/date";

// Components
import CategoryDropdown from "../CategoryDropdown";
import DynamicInput from "../DynamicInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Services
import ImageService from "services/ImageService";

import { IMAGE_PROPS } from "consts";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      client: "",
    };

    this.close = this.close.bind(this);
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this);
    this.handleUpdateOperators = this.handleUpdateOperators.bind(this);
    this.handleUpdateOperators = this.handleUpdateOperators.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
  }

  async componentDidMount() {
    await this.getTags();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.main.serviceType !== prevProps.main.serviceType ||
      this.props.main.tagsChanged
    ) {
      if (this.props.main.tagsChanged) {
        this.props.tagsChanged(false);
      }

      if (prevProps.main.serviceType && prevProps.main.serviceType !== "") {
        this.props.history.push("/");
      }

      let defaultFilter = "extension=jpeg";

      await this.props.setFilter(defaultFilter);

      this.setState(
        { filters: [], client: this.props.client.client },
        async () => {
          await this.getTags();
          this.props.onSubmitForm();
        }
      );
    }
  }

  async getTags() {
    const { client } = this.props.client;
    const { serviceType } = this.props.main;

    if (serviceType && serviceType != "") {
      const response = await ImageService.getTags(
        client,
        serviceType.value.toLowerCase()
      );

      if (!response.errors) {
        // Nested Object destructuring
        const { fields } = response;
        this.createFilters(fields);
      }
    }
  }

  handleUpdateFilters(id, value, name) {
    const _filters = [...this.state.filters];

    if (_filters[id].type === "datetime" && _filters[id].fields) {
      _filters[id].fields.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
      });
    } else if (_filters[id].type === "array") {
      _filters[id].value = value.toString();
    } else {
      _filters[id].value = value;

      if (_filters[id].type === "bool") {
        if (!JSON.parse(value)) {
          _filters[id].value = "true";
          this.handleUpdateOperators(id, "!=");
        } else {
          this.handleUpdateOperators(id, "=");
        }
      }
    }

    this.setState({
      filters: _filters,
    });
  }

  handleUpdateOperators(id, operator) {
    const _filters = [...this.state.filters];
    _filters[id].operator = operator;

    this.setState({
      filters: _filters,
    });
  }

  createFilters(tags) {
    let filters = [];

    Object.values(tags).map((item) => {
      if (item.stringValue) {
        if (
          (item.stringValue === "string" || item.stringValue === "int") &&
          item.key != "serviceType"
        ) {
          filters.push({
            name: item.key,
            value: "",
            operator: "=",
            order:
              item.stringValue === "string"
                ? 0
                : item.stringValue === "int"
                ? 1
                : null,
            type: item.stringValue,
            useAutocomplete: false,
          });
        } else if (item.stringValue === "datetime") {
          filters.push({
            name: item.key,
            value: "",
            type: "datetime",
            useAutocomplete: false,
            operator: "",
            order: 3,
            fields: [
              {
                name: "from",
                value: "",
                parent: item.key,
              },
              {
                name: "to",
                value: "",
                parent: item.key,
              },
            ],
          });
        } else {
          if (
            item.key != "_id" &&
            item.key != "key_1" &&
            item.key != "serviceType"
          ) {
            filters.push({
              name: item.key,
              value: "",
              operator: "",
              order: 2,
              type: item.stringValue,
              useAutocomplete: false,
            });
          }
        }
      } else if (item.numberValue) {
        filters.push({
          name: item.key,
          value: "",
          operator: "",
          order: 1,
          type: "int",
          useAutocomplete: false,
        });
      } else if (item.booleanValue) {
        filters.push({
          name: item.key,
          value: "",
          operator: "=",
          order: 1,
          type: "bool",
          useAutocomplete: false,
        });
      } else if (item.fieldsResponse) {
        item.fieldsResponse.map((subItem) => {
          filters.push({
            name: subItem.key,
            value: "",
            operator: "",
            order: 2,
            hasParent: true,
            type:
              item.stringValue === "string"
                ? "string"
                : item.stringValue === "int"
                ? "int"
                : null,
            useAutocomplete: false,
          });
        });
      }
    });

    // Sorted by order position
    let sortedFilters = filters.sort((a, b) => {
      return a.order - b.order;
    });

    // Sorted by field name
    sortedFilters.sort((a, b) => {
      if (a.type !== "datetime" && b.type !== "datetime") {
        return a.name.localeCompare(b.name);
      }
    });

    this.setState({
      filters: sortedFilters,
    });
  }

  createInputs(fields) {
    const { client } = this.props.client;

    let inputs = [];

    fields.map((item, index) => {
      if (item.type === "int") {
        inputs.push(
          <DynamicInput
            key={item.name}
            item={item}
            col="12"
            id={index}
            updateFilters={this.handleUpdateFilters}
            updateOperators={this.handleUpdateOperators}
          />
        );
      } else if (item.type === "array") {
        inputs.push(
          <CategoryDropdown
            className="filtering-input"
            placeholder="Search"
            label={item.name}
            key={item.name}
            name={item.name}
            type={"updateFilters"}
            id={index}
            item={item}
            options={[]}
            selected={[]}
            size={12}
            updateFilters={this.handleUpdateFilters}
            client={client}
          />
        );
      } else if (item.type === "datetime") {
        inputs.push(
          <div className="col-md-12" key={item.name}>
            <div className="datetime-group">
              <div className="form-label">{item.name}</div>
              <div className="row">
                {item.fields.map((field) => {
                  return (
                    <div
                      className="form-input-single col-md-6"
                      key={field.name}
                    >
                      <label className="form-label-child">{field.name}: </label>
                      <DatePicker
                        value={field.value}
                        dateFormat="dd/MM/yyyy"
                        selected={field.value}
                        name={field.name}
                        id={field.name}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="yyy-MM-dd HH:mm:ss"
                        data-id={index}
                        autoComplete="off"
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                          },
                        }}
                        onChange={(date) =>
                          this.handleUpdateFilters(index, date, field.name, "")
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else if (item.type === "bool") {
        inputs.push(
          <div className="form-input-single col-md-12 checkbox" key={item.name}>
            <label className="form-label">{item.name}: </label>
            <div className="selection-group">
              <div className="single-select">
                <input
                  name={item.name}
                  value={"true"}
                  type="radio"
                  id={item.name + "Yes"}
                  onChange={(e) =>
                    this.handleUpdateFilters(index, e.target.value, item.name)
                  }
                />
                <label htmlFor={item.name + "Yes"}>Yes</label>
                <input
                  defaultChecked
                  name={item.name}
                  value={"false"}
                  type="radio"
                  id={item.name + "No"}
                  onChange={(e) =>
                    this.handleUpdateFilters(index, e.target.value, item.name)
                  }
                />
                <label htmlFor={item.name + "No"}>No</label>
              </div>
            </div>
          </div>
        );
      } else {
        inputs.push(
          <div className="form-input-single col-md-12" key={item.name}>
            <label className="form-label">{item.name}: </label>
            <input
              type="text"
              data-id={index}
              name={item.name}
              value={item.value}
              id={item.name}
              onChange={(e) => {
                this.handleUpdateFilters(
                  e.target.dataset.id,
                  e.target.value.trim()
                );
              }}
              autoComplete="off"
            />
          </div>
        );
      }
    });

    return inputs;
  }

  close() {
    const {
      sidebar: { isOpen },
      close,
    } = this.props;
    if (isOpen) {
      close();
    }
  }

  handleClickOutside = () => {
    this.close();
  };

  async submitFilters(e) {
    e.preventDefault();

    let filterString = "";

    this.state.filters.map((filter) => {
      if (filter.value) {
        filterString +=
          filter.type === "int" || filter.type === "bool"
            ? `${filter.name}${filter.operator}${filter.value},`
            : filter.type === "array"
            ? filter.name === IMAGE_PROPS.TEST_IDS
              ? `${filter.name}*=[${filter.value.replace(/,/g, "|")}],`
              : `${filter.name}=[${filter.value.replace(/,/g, "|")}],`
            : `${filter.name}=${filter.value},`;
      } else if (filter.fields) {
        let fromValue = "";
        let toValue = "";

        const filterName = filter.name;

        filter.fields.map((field) => {
          if (field.value && field.name === "from") {
            fromValue = convertDateTimeToString(field.value);
          } else if (field.value && field.name === "to") {
            toValue = convertDateTimeToString(field.value);
          }
        });

        if (fromValue && toValue) {
          filterString += `${filterName}>=${fromValue},${filterName}<=${toValue},`;
        } else if (fromValue) {
          filterString += `${filterName}=${fromValue},`;
        } else if (toValue) {
          filterString += `${filterName}=${toValue},`;
        }
      }
    });

    if (!filterString.includes("extension")) {
      filterString += `${"extension"}=${"jpeg"},`;
    }

    // Set new state for filter
    await this.props.setFilter(filterString.substr(0, filterString.length - 1));

    this.props.history.push("/");
    this.props.setJobId("");

    this.props.onSubmitForm();
  }

  render() {
    const classes = cn("filters-sidebar noselect", {
      show: this.props.sidebar.isOpen,
    });
    return (
      // Filters sidebar starts here
      <div className={classes} id="sidebar">
        <div className="flex-container flex-space-between flex-align-items-center">
          <div className="sidebar-title">
            <h4>Filters</h4>
          </div>

          <button
            id="closeSidebar"
            className="close-sidebar"
            onClick={this.close}
          >
            <i className="fe fe-x"></i>
          </button>
        </div>
        <div className="sidebar-body">
          <form>
            <div className="row">{this.createInputs(this.state.filters)}</div>
            <div className="apply-filters-btn">
              <button
                type="submit"
                onClick={(e) => {
                  this.submitFilters(e);
                }}
              >
                Apply Filter Set
              </button>
            </div>
          </form>
        </div>

        <div className="sidebar-footer"></div>
      </div>
      // Filters sidebar ends here
    );
  }
}

export default onClickOutside(Sidebar);
