import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions } from './actions';
import PrivateRoute from './components/PrivateRoute';
import Main from './screens/private';
import history from 'store/history';
import Loader from 'react-loader-spinner';
import Header from './components/Header';
import ToastNotification from './components/ToastNotification';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

// styles
import './App.css';
import { useAuth0 } from './context/auth-context';
import Login from './screens/public/components/Login/Login';

const App = (props) => {
  const { loading, user, logout, userToken } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(AuthActions.setUser(user));
    }
  }, [user]);

  if (loading) {
    return (
      <div className='loading-indicator'>
        <Loader type='ThreeDots' color='#5c96d3' height={100} width={100} />
      </div>
    );
  }

  return (
    <Router history={history}>
      {user && <Header user={user} history={history} logout={logout} />}
      <Suspense fallback={<div>Loading</div>}>
        <ToastNotification />
        <Switch>
          <PrivateRoute exact path='/' component={Main} token={userToken} />
          <Route exact path='/login' component={Login} />
        </Switch>
      </Suspense>
      {!user && (
        <Redirect
          from='/'
          to={{
            pathname: '/login',
            returnUrl: history.location.search,
          }}
        />
      )}
    </Router>
  );
};

export default App;
