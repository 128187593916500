import React, { Component } from "react";
import { connect } from "react-redux";

// Shared Components
import Sidebar from "./components/Sidebar";
import Container from "./../../components/Container";
import Icon from "./../../components/Icon";

// Actions
import { MainActions, JobActions, ClientActions } from "../../actions";

// Utils
import ROLE from "../../utils/enums/role";

// Private screen components
import ImageModal from "./components/ImageModal";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal/";
import Tags from "./components/Tags";
import UpdateImages from "./components/UpdateImages";
import ConfirmPtagDone from "./components/ConfirmPtagDone";

// Image url formatter
import imageUrlFormatter from "../../utils/helpers/imageUrlFormatter";

// Virtual scrolling
import ImageList from "./components/ImageList";

// Services
import ImageService from "./../../services/ImageService";

// Lightbox component
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { merge } from "../../utils/helpers/merge";

import { debounce } from "../../utils/helpers/debounce";
import { SERVICE_TYPES } from "../../consts";
import { getUserRoles } from "../../utils/helpers/getUserRoles";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      imageModalId: "",
      showConfirmDelete: false,
      showUpdateFiles: false,
      filters: [],
      showConfirmDone: false,
      selected: {},
      selectedAll: false,
      listHeight: 0,
      showImageList: false,
      imageIndex: 0,
      isLightboxVisible: false,
      selectedImages: [],
      tolerance: 0,
      selectOnMouseMove: false,
      isOther: false,
      readOnly: false,
      token: "",
      mainSrc: "",
      prevSrc: "",
      nextSrc: "",
    };

    this.openImageModal = this.openImageModal.bind(this);
    this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
    this.openConfirmDone = this.openConfirmDone.bind(this);
    this.openUpdateFiles = this.openUpdateFiles.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.getSelectedImagesIds = this.getSelectedImagesIds.bind(this);
    this.getSelectedPaths = this.getSelectedPaths.bind(this);
    this.handleOpenLightBox = this.handleOpenLightBox.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.toggleSelectOnMouseMove = this.toggleSelectOnMouseMove.bind(this);
    this.handleWatchedVideo = this.handleWatchedVideo.bind(this);
  }

  parseQueryString = () => {
    let pairs = this.props.location.search.slice(1).split("&");

    let params = [];

    pairs.forEach(function (pair) {
      if (pair !== "") {
        let decodedPair = decodeURIComponent(pair);
        let operator = decodedPair.match(/<=|<|>=|>|=/g).toString();

        decodedPair = decodedPair.split(operator);

        params.push({
          name: decodedPair[0],
          value: decodedPair[1],
        });
      }
    });

    return params;
  };

  authImgSrc = (url, action = "main", imageIndex) => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        if (action === "main") {
          this.setState({
            ...this.state,
            mainSrc: URL.createObjectURL(blob),
            imageIndex,
          });
        }
        if (action === "prev") {
          this.setState({
            ...this.state,
            prevSrc: URL.createObjectURL(blob),
            imageIndex,
          });
        }
        if (action === "next") {
          this.setState({
            ...this.state,
            nextSrc: URL.createObjectURL(blob),
            imageIndex,
          });
        }
      })
      .catch((err) => console.error("Error fetching image", err));
  };

  async componentDidMount() {
    const {
      location: { search },
      token,
    } = this.props;

    this.setState({ ...this.state, token: token });

    // Set images container height
    this.handleUpdateImageListHeight();

    // Getting query parameters from url
    const params = new URLSearchParams(search);

    const { user } = this.props;
    const userRoles = getUserRoles(user).toString();

    const job = params.get("jobId");
    const client = params.get("client");
    const jobType = params.get("jobType");
    const isAdmin = params.get("isAdmin");
    const productId = params.get("productId");
    const isOther = params.get("isOther");
    const extension = params.get("extension");
    const serviceType = params.get("serviceType");
    const subType = params.get("subType");
    const readOnly = params.get("readOnly");
    const testIds = params.get("testIds");
    const trolley = params.get("trolley");

    if (testIds) {
      params.delete("client");
      params.delete("location");
      this.setState({
        isOther: false,
      });
      if (
        serviceType &&
        SERVICE_TYPES.find((item) => item.value === serviceType.toUpperCase())
      ) {
        await this.props.setServiceType(
          SERVICE_TYPES.find((item) => item.value === serviceType.toUpperCase())
        );
      }
    }

    // First, check if query params have client and job id params
    if ((job && jobType) || isAdmin) {
      client && (await this.props.setClient(client));
      await this.props.setJobId(job);
      await this.props.setJobType(jobType);
      if (
        serviceType &&
        SERVICE_TYPES.find((item) => item.value === serviceType.toUpperCase())
      ) {
        await this.props.setServiceType(
          SERVICE_TYPES.find((item) => item.value === serviceType.toUpperCase())
        );
      } else {
        await this.props.setServiceType(SERVICE_TYPES[0]);
      }
      subType && this.props.setSubType(subType);
    }

    if (readOnly && readOnly !== "") {
      this.setState({
        readOnly: true,
      });
    }

    if (isOther && isOther !== "") {
      this.setState({
        isOther: true,
      });
    }
    // In the purpose of filtering files, we have to remove jobId, jobType and client from url
    params.delete("client");
    params.delete("jobId");
    params.delete("jobType");
    params.delete("isAdmin");
    params.delete("isOther");
    params.delete("serviceType");
    params.delete("subType");

    if (
      (jobType &&
        (jobType === "MLC" ||
          jobType === "CLASSIFIER" ||
          jobType === "CLUSTER" ||
          jobType === "DETECTOR")) ||
      subType === "MODEL_TEST_DETECTOR"
    ) {
      client && params.append("client", client);
    }

    if (jobType && jobType === "MODEL_TEST") {
      params.append("client", client);
    }

    if (
      (userRoles &&
        userRoles.includes("DATA_ANALYST") &&
        this.props.main.serviceType &&
        this.props.job.jobId !== "" &&
        this.props.job.jobType !== "") ||
      (userRoles.includes("ADMINISTRATOR") &&
        this.props.main.serviceType &&
        isAdmin !== "") ||
      userRoles.includes("ADMINISTRATOR" || "PROJECT_MENAGER") ||
      userRoles.includes("DATA_ANALYST_ADMIN" || "DATA_ANALYST") ||
      userRoles.includes("DATA_ANALYST") ||
      (userRoles.includes("DATA_ANALYST") && productId)
    ) {
      if (productId) {
        params.set("productList", productId);
        params.delete("productId");
        params.delete("client");
        params.delete("location");
        this.setState({
          isOther: true,
        });
        if (
          serviceType &&
          SERVICE_TYPES.find((item) => item.value === serviceType.toUpperCase())
        ) {
          await this.props.setServiceType(
            SERVICE_TYPES.find(
              (item) => item.value === serviceType.toUpperCase()
            )
          );
        }
      }

      params.delete("readOnly");

      if (!extension) {
        params.append("extension", "jpeg");
      }

      let filter = params.toString();

      await this.props.setFilter(
        decodeURIComponent(filter.split("&").join(","))
      );

      await this.fetchData();

      // Set images container height
      this.handleUpdateImageListHeight();

      // Handle on scroll and get more data when user scrolled to the bottom
      const element = document.getElementById("images");
      if (element) element.addEventListener("scroll", this.handleOnScroll);

      window.onkeydown = (e) => {
        // SELECT ALL (CMD+A)
        if (e.ctrlKey && e.keyCode === 65) {
          let selected = this.state.images.reduce(
            (mapper, obj) => Object.assign(mapper, { [obj._id]: obj }),
            {}
          );
          this.setState({ selected: selected, selectedAll: true });
        }
      };

      // Window resize event - Update images container when user resizes window
      window.addEventListener(
        "resize",
        debounce(
          () => {
            this.handleUpdateImageListHeight();
          },
          200,
          false
        ),
        false
      );
    }
  }

  handleUpdateImageListHeight = () => {
    this.setState({
      listHeight:
        Math.max(document.documentElement.clientHeight, window.innerHeight) -
        137,
    });
  };

  handleOnScroll = async (event) => {
    var element = event.target;
    if (
      Math.ceil(element.scrollTop + element.clientHeight) ===
      element.scrollHeight
    ) {
      this.loadMore();
    }
  };

  componentWillUnmount() {
    const element = document.getElementById("images");
    if (element) element.removeEventListener("scroll", this.handleOnScroll);
  }

  // Get tags
  getTags = async () => {
    const { client } = this.props.client;
    const { serviceType } = this.props.main;

    if (serviceType) {
      const response = await ImageService.getTags(
        client,
        serviceType.value.toLowerCase()
      );

      // Nested Object destructuring
      const { fields } = response;
      this.createFilters(fields);
    }
  };

  // Create filters
  createFilters = (tags) => {
    let filters = [];

    tags.map((item) => {
      if (item.stringValue) {
        if (item.stringValue === "string" || item.stringValue === "int") {
          filters.push({
            name: item.key,
            value: "",
            operator: "",
            type: item.stringValue,
          });
        } else if (item.stringValue === "datetime") {
          filters.push({
            name: item.key,
            value: "",
            type: "datetime",
            operator: "",
            fields: [
              {
                name: "from",
                value: "",
                parent: item.key,
              },
              {
                name: "to",
                value: "",
                parent: item.key,
              },
            ],
          });
        } else {
          filters.push({
            name: item.key,
            value: "",
            operator: "",
            type: item.stringValue,
          });
        }
      } else if (item.numberValue) {
        filters.push({
          name: item.key,
          value: "",
          operator: "",
          type: "int",
        });
      } else if (item.fieldsResponse) {
        item.fieldsResponse.map((subItem) => {
          filters.push({
            name: subItem.key,
            value: "",
            operator: "",
            hasParent: true,
            type:
              item.stringValue === "string"
                ? "string"
                : item.stringValue === "int"
                ? "int"
                : null,
          });
        });
      }
    });
  };

  // Fetch data
  fetchData = async () => {
    const { client } = this.props.client;
    const { filter, serviceType } = this.props.main;
    const { isOther } = this.state;
    if (
      filter.includes("extension") &&
      (filter.split(",").length > 1 || client != "" || serviceType)
    ) {
      // call service for getting images
      await this.props.getImages(
        client,
        filter,
        isOther,
        40,
        serviceType ? serviceType.value.toLowerCase() : ""
      );

      this.setState({
        images: this.props.main.images,
        selectedAll: false,
        selected: {},
        showImageList: this.props.main.images.length > 0 ? true : false,
      });
    }
  };

  // Handle close sidebar
  handleCloseSidebar() {
    this.props.closeSidebar();
    // Enable scrollbar when sidebar is closed
    document.body.style.overflowY = "auto";
  }

  // Handle select image
  handleSelectImage = (e, item) => {
    e.stopPropagation();

    let { selected, images } = this.state;

    if (!item) {
      let isChecked = e.target.checked;

      if (isChecked) {
        let selected = images.reduce(
          (mapper, obj) => Object.assign(mapper, { [obj._id]: obj }),
          {}
        );
        this.setState({ selected: selected, selectedAll: true });
      } else {
        this.setState({ selected: {}, selectedAll: false, selectedImages: [] });
      }
    } else {
      selected[item._id] = selected[item._id] ? undefined : item;
      this.setState({
        selected: selected,
        selectedAll:
          Object.keys(selected).filter(
            (id) => typeof selected[id] !== "undefined"
          ).length === images.length
            ? true
            : false,
      });
    }
  };

  openImageModal = (id, tags) => {
    this.props.openImageModal(id, tags);
  };

  // Getting more data
  loadMore = async () => {
    const { client } = this.props.client;
    const { filter, next, serviceType } = this.props.main;
    const { isOther } = this.state;

    if (next !== "") {
      await this.props.getMoreImages(
        client,
        filter,
        isOther,
        40,
        next,
        serviceType.value.toLowerCase()
      );
      this.setState({
        images: this.props.main.images,
        selectedAll: false,
      });
    }
  };

  openConfirmDeleteModal = () => {
    this.setState((prevState) => ({
      showConfirmDelete: !prevState.showConfirmDelete,
    }));
  };

  closeConfirmDeleteModal = () => {
    this.setState({
      showConfirmDelete: false,
    });
  };

  getSelectedImagesIds = () => {
    let ids = "";
    const { selected } = this.state;

    Object.values(selected).map((item) => {
      if (item) ids += `${item._id},`;
    });

    return ids.substr(0, ids.length - 1);
  };

  deleteFiles = async () => {
    const { client } = this.props.client;
    const { serviceType } = this.props.main;

    const ids = this.getSelectedImagesIds();
    const response = await ImageService.deleteFiles(
      client,
      ids,
      serviceType.value.toLowerCase()
    );

    await this.refreshImages();

    return response;
  };

  refreshImages = async () => {
    const { client } = this.props.client;
    const { filter, serviceType } = this.props.main;
    const { isOther } = this.state;

    // call service for getting images
    await this.props.getImages(
      client,
      filter,
      isOther,
      this.props.main.images.length,
      serviceType.value.toLowerCase()
    );

    this.setState(
      {
        images: this.props.main.images,
        selected: {},
        selectedAll: false,
      },
      () => {
        this.getImagesPaths();
      }
    );
  };

  openUpdateFiles = () => {
    this.setState({
      showUpdateFiles: true,
    });
  };

  closeUpdateFiles = () => {
    this.setState((prevState) => ({
      showUpdateFiles: !prevState.showUpdateFiles,
    }));
  };

  getSelectedPaths = () => {
    let paths = [];
    const { selected } = this.state;

    Object.values(selected).map((item) => {
      if (typeof item != "undefined") {
        paths.push(item.imageUrl);
      }
    });
    return paths;
  };

  getImagesPaths = (imageIndex) => {
    const { images } = this.state;
    //Main image
    if (imageIndex !== null && imageIndex >= 0) {
      this.authImgSrc(
        imageUrlFormatter(
          images[imageIndex].imageUrl,
          images[imageIndex].fileType
        ),
        "main",
        imageIndex
      );
      //Next image
      this.authImgSrc(
        imageUrlFormatter(
          images[(imageIndex + 1) % images.length].imageUrl,
          images[imageIndex].fileType
        ),
        "next",
        imageIndex
      );
      //Previous image
      this.authImgSrc(
        imageUrlFormatter(
          images[(imageIndex + images.length - 1) % images.length].imageUrl,
          images[imageIndex].fileType
        ),
        "prev",
        imageIndex
      );
    }
  };

  openConfirmDone = () => {
    this.setState((prevState) => ({
      showConfirmDone: !prevState.showConfirmDone,
    }));
  };

  closeConfirmDone = () => {
    this.setState({
      showConfirmDone: false,
      selected: {},
      selectedAll: {},
      selectedImages: []
    });
  };

  handleOpenLightBox = async (imageIndex) => {
    this.getImagesPaths(imageIndex);
    this.setState({
      imageIndex,
      isLightboxVisible: true,
      mainSrc: "",
      nextSrc: "",
      prevSrc: "",
    });
  };

  handleSelection(keys) {
    let currentSelectedItems = this.state.selectedImages;

    this.setState(
      {
        selectedImages: merge(currentSelectedItems, keys),
      },
      () => {
        const { images, selectedImages, selected } = this.state;
        let selectedImagesObj = {};

        images &&
          images.map((item) => {
            if (selectedImages.includes(item._id)) {
              Object.assign(selectedImagesObj, { [item._id]: item }, {});
            }
          });

        this.setState({
          selected: { ...this.state.selected, ...selectedImagesObj },
          selectedAll:
            Object.keys(selected).filter(
              (id) => typeof selected[id] !== "undefined"
            ).length === images.length
              ? true
              : false,
        });
      }
    );
  }

  handleToleranceChange(e) {
    this.setState({
      tolerance: parseInt(e.target.value),
    });
  }

  toggleSelectOnMouseMove() {
    this.setState({
      selectOnMouseMove: !this.state.selectOnMouseMove,
    });
  }

  async handleWatchedVideo(e, item) {
    const { serviceType } = this.props.main;
    const { client } = this.props.client;
    const { email } = this.props.user;

    const tags = [
      { key: "watchedVideo", value: !item.handleWatchedVideo },
      { key: "editor", value: email },
    ];

    const response = await ImageService.updateFile(
      client,
      tags,
      item._id,
      serviceType.value.toLowerCase()
    );

    if (!response.errors) {
      await this.refreshImages();
    }
  }

  render() {
    const { show, id } = this.props.main.image_modal;
    const { showTags, serviceType } = this.props.main;
    const { client } = this.props.client;
    const {
      selected,
      selectedAll,
      images,
      listHeight,
      showConfirmDelete,
      showConfirmDone,
      showUpdateFiles,
      showImageList,
      imageIndex,
      isLightboxVisible,
      readOnly,
      mainSrc,
      prevSrc,
      nextSrc,
    } = this.state;

    const numOfSelected = Object.keys(selected).filter(
      (id) => typeof selected[id] !== "undefined"
    ).length;

    const { user } = this.props;
    const userRoles = getUserRoles(user).toString();

    const isExternalDA = userRoles
      ? userRoles.toString() === ROLE.EXTERNAL_DATA_ANALYST
      : false;

    return (
      <div className="app-container">
        <ImageList
          height={listHeight}
          show={showImageList}
          items={images}
          selected={selected}
          handleSelectImage={this.handleSelectImage}
          openImageModal={this.openImageModal}
          openLightBox={this.handleOpenLightBox}
          handleSelection={this.handleSelection}
          tolerance={this.state.tolerance}
          selectOnMouseMove={this.state.selectOnMouseMove}
          handleWatchedVideo={this.handleWatchedVideo}
        />

        {isLightboxVisible && (
          <Lightbox
            mainSrc={mainSrc}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            onCloseRequest={() => this.setState({ isLightboxVisible: false })}
            onMovePrevRequest={() => {
              const index = (imageIndex + images.length - 1) % images.length;
              this.getImagesPaths(index);
              this.setState({
                imageIndex: index,
              });
            }}
            onMoveNextRequest={() => {
              const index = (imageIndex + 1) % images.length;
              this.getImagesPaths(index);
              this.setState({
                imageIndex: index,
              });
            }}
          />
        )}

        {images.length === 0 && (
          <Container>
            <div className="no-results">
              <span>No images</span>
              {this.props.user.roles &&
                this.props.user.roles.some((role) => {
                  return role.value === ROLE.DATA_ANALYST;
                }) &&
                this.props.client.client != "" &&
                this.props.job.jobId != "" &&
                this.props.job.jobType != "" && (
                  <div className="save-selected-dataset">
                    <button
                      type="button"
                      onClick={() => {
                        this.openConfirmDone();
                      }}
                    >
                      Done
                    </button>
                  </div>
                )}
            </div>
          </Container>
        )}

        {/* Image modal for showing current tags for selected image with adding and choosing existing tags forms */}
        <ImageModal
          show={show}
          id={id}
          refresh={this.refreshImages}
          onClose={this.props.closeImageModal}
          client={client}
          serviceType={serviceType}
          user={this.props.user}
          readOnly={this.state.readOnly}
        />

        {/* Confirm delete modal for delete files action */}
        <ConfirmDeleteModal
          show={showConfirmDelete}
          deleteFiles={this.deleteFiles}
          onClose={this.closeConfirmDeleteModal}
        />

        {/* Confirm PTAG done modal */}
        <ConfirmPtagDone
          show={showConfirmDone}
          paths={this.getSelectedPaths()}
          onClose={this.closeConfirmDone}
          jobId={this.props.job.jobId}
          jobType={this.props.job.jobType}
          client={!numOfSelected ? client : ""}
          imageCounter={numOfSelected}
          filter={!numOfSelected ? this.props.main.filter : ""}
          subType={this.props.job.subType}
          user={this.props.user}
        />
        <Sidebar
          onSubmitForm={this.fetchData}
          history={this.props.history}
          setJobId={this.props.setJobId}
        />

        {/* Actions related to images */}
        {this.props.job.jobId &&
          numOfSelected == 0 &&
          images.length > 0 &&
          !readOnly && (
            <div>
              <div className="save-selected-dataset done-job">
                <button
                  type="button"
                  onClick={() => {
                    this.openConfirmDone();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          )}
        {numOfSelected > 0 && !readOnly && (
          <div className="images-actions no-select">
            <div className="select-all-images flex-box flex-align-items-center">
              <div className="flex-box flex-align-items-center">
                <input
                  type="checkbox"
                  name="selectAll"
                  className="select-all-images-checkbox"
                  checked={selectedAll}
                  onChange={(e) => {
                    this.handleSelectImage(e);
                  }}
                />
              </div>
              <p>
                <strong>{numOfSelected}</strong> selected images
              </p>
            </div>

            <div className="actions-btn-group">
              <button
                className="add-btn"
                data-tooltip="Add tags"
                onClick={() => {
                  this.openUpdateFiles();
                }}
              >
                <Icon name="plus-square" />
              </button>
              {!isExternalDA && (
                <button
                  type="button"
                  data-tooltip="Remove files"
                  className="delete-btn"
                  onClick={this.openConfirmDeleteModal}
                >
                  <Icon name="trash-2" />
                </button>
              )}
            </div>

            <div className="save-selected-dataset">
              <button
                type="button"
                onClick={() => {
                  this.openConfirmDone();
                }}
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* Tags */}
        <Tags
          show={showTags}
          client={client}
          tagsChanged={this.props.tagsChanged}
        />

        {/* Update files */}

        {serviceType && (
          <UpdateImages
            show={showUpdateFiles}
            ids={this.getSelectedImagesIds()}
            refresh={this.refreshImages}
            onClose={this.closeUpdateFiles}
            client={client}
            user={this.props.user}
            serviceType={serviceType}
            isExternalDA={isExternalDA}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  main: state.main,
  client: state.client,
  job: state.job,
});

const mapDispatchToProps = (dispatch) => ({
  getImages: (client, filter, isOther, limit, serviceType) =>
    dispatch(
      MainActions.getImages(client, filter, isOther, limit, serviceType)
    ),
  getMoreImages: (client, filter, isOther, limit, nextPage, serviceType) =>
    dispatch(
      MainActions.getMoreImages(
        client,
        filter,
        isOther,
        limit,
        nextPage,
        serviceType
      )
    ),
  openImageModal: (id) => dispatch(MainActions.open(id)),
  closeImageModal: () => dispatch(MainActions.close()),
  deleteImages: (ids) => dispatch(MainActions.deleteImages(ids)),
  setFilter: (filter) => dispatch(MainActions.setFilter(filter)),
  setJobId: (id) => dispatch(JobActions.setJobId(id)),
  setJobType: (type) => dispatch(JobActions.setJobType(type)),
  setClient: (client) => dispatch(ClientActions.setClient(client)),
  setServiceType: (type) => dispatch(MainActions.setServiceType(type)),
  tagsChanged: (flag) => dispatch(MainActions.tagsChanged(flag)),
  setSubType: (type) => dispatch(JobActions.setSubType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
