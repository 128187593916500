import {IMAGE_PATH, VIDEO_PATH, VIDEO_TOKEN, FILE_TYPE} from "../../consts";

const imageUrlFormatter = (url, fileType, dimensions) => {
  if (url && url.indexOf('s3') !== -1){
    const imageBucket = url.match(/^s3?\:\/\/([^\/:?#]+)/)[1];

    if (fileType !== "videos") {
      const imageObject = {
          bucket: imageBucket,
          key: decodeURIComponent(url).replace(/(^\w+:|^)\/(\/(.*?)\/)/, ''),
          edits: {
            resize: fileType != FILE_TYPE.CLUSTERED_CROPS ? dimensions : null
        }
      }
      if (imageObject.key.substring(imageObject.key.lastIndexOf(".") + 1, imageObject.key.length) !== 'jpeg') {
        imageObject.key = imageObject.key.substring(0, imageObject.key.lastIndexOf(".") + 1) + 'jpeg';
      }
      // Encode a string in base-64

      return`${IMAGE_PATH + '/' + btoa(JSON.stringify(imageObject)) + VIDEO_TOKEN}`;
    }
    else{
      return `${VIDEO_PATH + '/' + decodeURIComponent(url.replace(`${imageBucket}`, "")).replace(/^([^\/]+)/, '').replace(/^\/+/g, '') + VIDEO_TOKEN}`;
    }
  }
}

export default imageUrlFormatter;