import {connect} from 'react-redux';

// Components
import Tags from './Tags';

// Actions 
import MainActions from './../../../../actions/mainActions';

const mapStateToProps = state => ({
  client: state.client,
  user: state.user,
  main: state.main
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(MainActions.closeTags())
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
