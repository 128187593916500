import * as AUTH_TYPES from '../types';
import AuthHelper from "../utils/helpers/authHelper";
import AuthService from "../services/AuthService";


class AuthActions{
  static setUser(user) {
    return async (dispatch) => {
      AuthHelper.setUser(user);
      return await dispatch({
        type: AUTH_TYPES.SET_USER,
        payload: user
      });
    }
  }

  static removeUser(){
    return async (dispatch) => {
      const response = await AuthService.logout();
      if(response && !response.errors && AuthHelper.getUser()){
        AuthHelper.removeUser();
      }
      return await dispatch({
        type: AUTH_TYPES.REMOVE_USER
      })
    }
  }
}

export default AuthActions;
