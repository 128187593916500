export const GET_IMAGES = 'GET_IMAGES';
export const GET_MORE_IMAGES = 'GET_MORE_IMAGES';
export const OPEN_IMAGE_MODAL = 'OPEN_IMAGE_MODAL';
export const CLOSE_IMAGE_MODAL = 'CLOSE_IMAGE_MODAL';
export const DELETE_IMAGES = 'DELETE_IMAGES';
export const OPEN_TAGS = 'OPEN_TAGS';
export const CLOSE_TAGS = 'CLOSE_TAGS';
export const UPDATE_FILES = 'UPDATE_FILES';
export const SET_FILTER = 'SET_FILTER';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const TAGS_CHANGED = 'TAGS_CHANGED';