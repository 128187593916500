import GraphQL from "../../utils/graphql";
import { SIGN_IN, CHANGE_INITIAL_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, GET_ACCESS_TOKEN, SIGN_OUT } from '../../graphql/schemas';
import loginFormatter from '../../utils/helpers/loginFormatter';

class AuthService {
  static async login(credentials) {
    const input = credentials;

    const data = await GraphQL.mutation({
      mutation: SIGN_IN,
      variables: {
        input
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        success: false
      }
    }
    else{
      const signIn = data.signIn;
      return loginFormatter(signIn, credentials);
    }
  }

  static async changeInitialPassword(credentials) {
    const input = credentials;

    const data = await GraphQL.mutation({
      mutation: CHANGE_INITIAL_PASSWORD,
      variables: {
        input
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        success: false
      }
    }
    else{
      const changeInitialPassword = data.changeInitialPassword;
      return loginFormatter(changeInitialPassword, credentials);
    }
  }

  static async forgotPassword(username) {

    const data = await GraphQL.mutation({
      mutation: FORGOT_PASSWORD,
      variables: {
        username
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        success: false
      }
    }

    const response = data.resetPassword;

    if(response){
      return {
        errors: null,
        message: response.message,
        destination: response.destination,
        success: true
      }
    }
  }

  static async resetPassword(credentials) {
    const input = credentials;
    const data = await GraphQL.mutation({
      mutation: RESET_PASSWORD,
      variables: {
        input
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        success: false
      }
    }

    const response = data.resetPassword;

    if(response){
      return {
        errors: null,
        message: response.message,
        destination: response.destination,
        success: true
      }
    }
  }

  static async refreshToken(username, refreshToken){
    const data = await GraphQL.mutation({
      mutation: GET_ACCESS_TOKEN,
      variables: {
        input: {
          username: username,
          refreshToken: refreshToken
        }
      }
    })

    const getAccessToken = data.getAccessToken;
    return loginFormatter(getAccessToken);
  }

  static async logout() {
    const data = await GraphQL.mutation({
      mutation: SIGN_OUT
    })
    return data;
  }
}

export default AuthService;
