import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { ApolloClient } from 'apollo-client';
import { ApolloLink, fromPromise } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import { Observable } from 'apollo-link'
import GraphQL from "../utils/graphql";


const proxy = process.env.REACT_APP_PROXY_URL;


const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userToken, setUserToken] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const globalToken = await auth0FromHook.getTokenSilently();
        setUserToken(globalToken);
        setUser(user);

        /* Set URI for all Apollo GraphQL requests (backend api) */
        const httpLink = new HttpLink({
          uri: proxy
        });

        let token;

        /* Set in-memory token to reduce async requests */

        /* Create Apollo Link to supply token with either
        * in-memory ref or auth0 req'ed token or redirect (built into
        * getTokenSilently
        */
        const withTokenLink = setContext(async () => {
          // return token if there
          if (token) return { auth0Token: token };

          // else check if valid token exists with client already and set if so
          const newToken = await auth0FromHook.getTokenSilently();
          token = newToken;
          return { auth0Token: newToken };
        });

        /* Create Apollo Link to supply token in auth header with every gql request */
        const authLink = setContext((_, { headers }) => {
          // return the headers to the context so httpLink can read them
          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : {}
            }
          }
        });

        const errorLink = onError(
          ({ networkError, operation, forward }) => {
            if (networkError && networkError.statusCode === 401) {
              token = null;
              return new Observable(async (observer) => {
                const refreshToken = await auth0FromHook.getTokenSilently();
                operation.setContext(({ }) => ({
                  headers: {
                    authorization: token ? `Bearer ${refreshToken}` : {}
                    // Re-add old headers
                    // Switch out old access token for new one
                  }
                }));

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                return forward(operation).subscribe(subscriber);
              });
            }
          }
        );
        /* Create Apollo Link array to pass to Apollo Client */
        const link = ApolloLink.from([errorLink, withTokenLink, authLink, httpLink]);
        /* Set up local cache */
        const cache = new InMemoryCache();
        const defaultOptions = {
          query: {
              fetchPolicy: "no-cache",
              errorPolicy: "all",
          },
          mutate: {
              errorPolicy: 'all'
          }
      };
        /* Create Apollo Client */
        const client = new ApolloClient({
          link,
          cache,
          defaultOptions
        });

        GraphQL.init(client);
      }
      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        userToken,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};