import React, { Component } from 'react';
import * as cn from 'classnames';

class Alert extends Component {

  constructor(props){
    super(props)

    this.state = {
      isDismissed: false
    }
  }

  handleOnDismissClick = () => {
    this.setState({
      isDismissed: true
    })
  }

  render(){
    const { isDismissed } = this.state;
    
    const {
      className,
      children,
      type,
      icon,
      hasExtraSpace,
      isDismissible,
      avatar,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave
    } = this.props;

    const classes = cn(
      "alert",
      `alert-${type}`,
      {
        "alert-icon": !!icon,
        "mt-5 mb-6": hasExtraSpace,
        "alert-dismissible": isDismissible,
        "alert-avatar": !!avatar,
      },
      className
    );

    const events = {
      onClick: onClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onPointerEnter: onPointerEnter,
      onPointerLeave: onPointerLeave
    };

    return (
      !isDismissed && (
          <div {...events} className={classes} role="alert">
          {isDismissible && (
            <button className="close" onClick={() => this.handleOnDismissClick()}></button>
          )}
          {children}
        </div>
      )
    )
  }
}

export default Alert;