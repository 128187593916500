import React, { useState, useEffect } from "react";
import * as cn from "classnames";

// Components
import Icon from "components/Icon";
import AddTagsForm from "./../Tags/AddTagsForm";
import Alert from "components/Alert";
import CategoryDropdown from "../CategoryDropdown";

// Service
import ImageService from "services/ImageService";

import tagsFormatter from "utils/helpers/tagsFormatter";
import ROLE from "utils/enums/role";
import { getUserRoles } from "utils/helpers/getUserRoles";

const isEmptyString = (str) => {
  return !str || str.length === 0;
};

const ImageModal = (props) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [currentTags, setCurrentTags] = useState({});
  const [tags, setTags] = useState([]);

  const [createErrors, setCreateErrors] = useState([]);
  const [createResponseMessage, setCreateResponseMessage] = useState("");

  const [deleteErrors, setDeleteErrors] = useState([]);
  const [deleteResponseMessage, setDeleteResponseMessage] = useState("");

  const [updateTagErrors, setupdateTagErrors] = useState([]);
  const [updateTagResponseMessage, setupdateTagResponseMessage] = useState("");

  const [applyTagErrors, setApplyTagErrors] = useState([]);
  const [applyTagResponseMessage, setApplyTagResponseMessage] = useState("");

  const [inputs, setInputs] = useState({});

  const [inputList, setInputList] = useState([
    { name: "", value: "", type: "" },
  ]);

  useEffect(() => {
    getTags();
    getCurrentTags(props.id);
  }, [props.id]);

  const mapTagsToSelectList = (fields) => {
    const _tags = [];

    Object.values(fields)
      .reduce((array, item) => {
        item.key === "editor" &&
        props.user.roles &&
        props.user.roles.some((role) => {
          return role.value === ROLE.DATA_ANALYST;
        })
          ? array.splice(
              array.findIndex((obj) => {
                return obj.key == item.key;
              }),
              1
            )
          : array.push(item);

        return array;
      }, [])
      .filter((item) => {
        if (item.stringValue) {
          _tags.push({
            display: item.key,
            value: item.key,
            type:
              item.stringValue === "string"
                ? "string"
                : item.stringValue === "array"
                ? "array"
                : item.stringValue === "bool"
                ? "bool"
                : undefined,
          });
        } else if (item.numberValue) {
          _tags.push({
            display: item.key,
            value: item.key,
            type: "int",
          });
        }
      });

    _tags.sort((a, b) => a.value.localeCompare(b.value));
    setTags(
      [{ value: "", display: "Select tag" }].concat(
        _tags.filter((item) => {
          return isExternalDA
            ? item.value === "productList" ||
                item.value === "modelTestType" ||
                item.value === "testIds" ||
                item.value === "trolley"
            : true;
        })
      )
    );
  };

  const getTags = async () => {
    const { client } = props.client;
    const { serviceType } = props.main;

    if (serviceType && serviceType != "") {
      const response = await ImageService.getTags(
        client,
        serviceType.value.toLowerCase()
      );

      // Nested Object destructuring
      const { fields } = response;
      mapTagsToSelectList(fields);
    }
  };

  const getCurrentTags = async (id) => {
    const { serviceType } = props.main;
    const { client } = props.client;

    const filter = `_id=${id}`;
    if (serviceType && id != "") {
      const { images } = await ImageService.getFiles(
        client,
        filter,
        false,
        1,
        "",
        serviceType.value.toLowerCase()
      );
      let _tags = tagsFormatter(images);

      _tags.sort((a, b) => a.name.localeCompare(b.name));

      Object.values(_tags)
        .reduce((array, item) => {
          item.name === "editor" &&
          props.user.roles &&
          props.user.roles.some((role) => {
            return role.value === ROLE.DATA_ANALYST;
          })
            ? array.splice(
                array.findIndex((obj) => {
                  return obj.name == item.name;
                }),
                1
              )
            : array.push(item);

          return array;
        }, [])
        .filter((item) => {
          return isExternalDA
            ? item.name === "productList" ||
                item.name === "modelTestType" ||
                item.name === "testIds" ||
                item.name === "trolley"
            : item.name != "_id";
        })
        .map((item) => {
          if (item.children) {
            item.children.map((subItem) => {
              setCurrentTags((currentTags) => ({
                ...currentTags,
                [subItem.name]: { ...subItem, isEditing: false },
              }));
            });
          } else {
            setCurrentTags((currentTags) => ({
              ...currentTags,
              [item.name]: { ...item, isEditing: false },
            }));
          }
        });
    }
  };

  const renderCurrentTags = () => {
    const { serviceType } = props.main;
    return Object.values(currentTags).map((item, idx) => (
      <div className="tag-item" key={idx}>
        <div className="tag-name">
          <p>{item.name}</p>
        </div>
        {renderCurrentTagValue(item, idx, serviceType)}
        {!props.readOnly && (
          <div className="manage-tag">
            {item.isEditing && (
              <button
                className="update-tag"
                onClick={() => handleUpdate(item.name, inputs[item.name])}
              >
                <Icon name="save" />
              </button>
            )}
            {!item.isEditing && (
              <button
                className="edit-tag"
                onClick={() => handleEdit(item.name)}
              >
                <Icon name="edit" />
              </button>
            )}
            <button
              className="remove-tag"
              onClick={() => handleDelete(props.id, item.name)}
            >
              <Icon name="trash-2" />
            </button>
          </div>
        )}
      </div>
    ));
  };

  const handleToggleForm = () => {
    setToggleForm(!toggleForm);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value.trim() });
  };

  const handleCategoryDropdownChange = (name, value) => {
    if (inputs[name]) {
      setInputs((inputs) => ({
        ...inputs,
        [name]: value && Array.isArray(value) ? value.join(",") : value,
      }));
    }
  };

  const handleDelete = async (id, name) => {
    const { client } = props.client;
    const { serviceType } = props.main;
    if (serviceType && serviceType !== "") {
      const response = await ImageService.deleteTagOnFile(
        client,
        id,
        name,
        serviceType.value.toLowerCase()
      );

      setDeleteResponseMessage([]);
      setDeleteResponseMessage("");

      if (response.errors) {
        setDeleteErrors(response.errors);
      } else {
        if (response.success) {
          setDeleteResponseMessage(response.message);
          delete currentTags[name];
          getCurrentTags(props.id);

          await props.refresh();

          setTimeout(() => {
            setDeleteResponseMessage("");
            handleOnClose();
          }, 2000);
        }
      }
    }
  };

  const handleUpdate = async (name, value) => {
    const { client } = props.client;
    const { serviceType } = props.main;

    if (serviceType && serviceType != "") {
      let tags = [
        {
          key: name,
          value: value,
        },
        {
          key: "editor",
          value: props.user.email,
        },
      ];

      const response = await ImageService.updateFile(
        client,
        tags,
        props.id,
        serviceType.value.toLowerCase()
      );
      setupdateTagResponseMessage("");
      setupdateTagErrors([]);

      if (response.errors !== null) {
        setupdateTagErrors(response.errors);
      } else {
        setupdateTagResponseMessage(response.message);

        await props.refresh();

        setCurrentTags((currentTags) => ({
          ...currentTags,
          [name]: {
            ...currentTags[name],
            value: inputs[name],
            isEditing: !currentTags[name].isEditing,
          },
        }));

        getCurrentTags(props.id);

        setTimeout(() => {
          setupdateTagResponseMessage("");
          handleOnClose();
        }, 1500);
      }
    }
  };

  const handleEdit = (name) => {
    setCurrentTags((currentTags) => ({
      ...currentTags,
      [name]: { ...currentTags[name], isEditing: !currentTags[name].isEditing },
    }));
    setInputs((inputs) => ({ ...inputs, [name]: currentTags[name].value }));
  };

  const handleCreate = async (tag) => {
    const { client } = props.client;
    const { serviceType } = props.main;

    if (serviceType !== "") {
      const response = await ImageService.createTags(
        client,
        [
          {
            tag: tag.name,
            type: tag.type,
          },
        ],
        serviceType.value.toLowerCase()
      );

      setCreateResponseMessage([]);
      setCreateResponseMessage("");

      if (response.errors !== null) {
        setCreateErrors(response.errors);
      } else {
        if (response.errors === null) {
          setCreateResponseMessage(response.message);

          getTags();
          document.getElementById("createTagForm").reset();

          setTimeout(() => {
            setCreateResponseMessage("");
          }, 2000);
        }
      }
    }
  };

  const handleApplyTag = async (e) => {
    e.preventDefault();

    setApplyTagErrors([]);
    setApplyTagResponseMessage("");

    const { client } = props.client;
    const { serviceType } = props.main;
    if (serviceType && serviceType != "") {
      let currentInputs = inputList
        .filter((item) => item.name != "" && item.value != "")
        .map((item) => {
          delete item.type;
          return { key: item.name, value: item.value };
        });

      if (currentInputs.length > 0) {
        let tags = [
          ...currentInputs,
          { key: "editor", value: props.user.email },
        ];

        if (
          (tags.some((item) => {
            return (
              item.key === "modelTestType" &&
              item.value.toUpperCase() !== "DETECTOR"
            );
          }) &&
            !tags.some((item) => {
              return item.key === "testIds";
            })) ||
          (tags.some((item) => {
            return item.key === "testIds";
          }) &&
            !tags.some((item) => {
              return (
                item.key === "modelTestType" &&
                item.value.toUpperCase() !== "DETECTOR"
              );
            }))
        ) {
          setApplyTagErrors([
            "Validation: modelTestType tag has to been applied with testIds tag",
          ]);
          return;
        } else {
          const response = await ImageService.updateFile(
            client,
            tags,
            props.id,
            serviceType.value.toLowerCase()
          );

          if (response.errors !== null) {
            setApplyTagErrors(response.errors);
          } else {
            setApplyTagResponseMessage(response.message);

            getTags();

            await props.refresh();

            setTimeout(() => {
              setApplyTagResponseMessage("");
              setInputList([{ name: "", value: "", type: "" }]);
              handleOnClose();
            }, 1000);
          }
        }
      }
    }
  };

  const handleOnClose = () => {
    props.onClose();
    setCurrentTags({});
    setInputList([{ name: "", value: "", type: "" }]);
    setApplyTagErrors([]);
    setCreateErrors([]);
    setDeleteErrors([]);
    document.getElementById("createTagForm") &&
      document.getElementById("createTagForm").reset();
  };

  const renderCurrentTagValue = (item, idx, serviceType) => {
    if (item.type === "array") {
      let itemValue = Array.isArray(item.value)
        ? item.value.toString()
        : item.value;
      return (
        <div className="tag-value category-dropdown-value">
          {!item.isEditing ? (
            itemValue &&
            itemValue.split(",").map((val, i) => (
              <span className="tag" key={i}>
                {val.replace(/[\[\]']+/g, "")}
              </span>
            ))
          ) : (
            <CategoryDropdown
              className="image-modal-input update-tag-input"
              placeholder="Search"
              label={item.name}
              key={item.name}
              name={item.name}
              type={"updateFileInput"}
              id={idx}
              item={item}
              selected={
                item.value && item.value.length > 0
                  ? item.value.split(",")
                  : null
              }
              size={12}
              updateTagValue={handleCategoryDropdownChange}
              client={undefined}
              serviceType={serviceType}
            />
          )}
        </div>
      );
    } else if (item.type === "bool") {
      return (
        <div className="tag-value">
          {!item.isEditing ? (
            <p>{item.value}</p>
          ) : (
            <div className="selection-group">
              <div className="single-select">
                <input
                  name={item.name}
                  checked={inputs[item.name] === "true"}
                  value={"true"}
                  type="radio"
                  id="yes"
                  onChange={(e) => handleInputChange(e)}
                />
                <label htmlFor="yes">Yes</label>
                <input
                  name={item.name}
                  checked={inputs[item.name] === "false"}
                  value={"false"}
                  type="radio"
                  id="no"
                  onChange={(e) => handleInputChange(e)}
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="tag-value">
          {!item.isEditing ? (
            <p>{item.value}</p>
          ) : (
            <input
              type="text"
              name={item.name}
              value={inputs[item.name]}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          )}
        </div>
      );
    }
  };

  const handleSelectTag = (e, index) => {
    const { value } = e.target;
    const tag = tags.find((tag) => tag.value === value);

    const list = [...inputList];

    list[index].name = value;
    list[index].type = tag.type;

    setInputList(list);
  };

  const handleSelectedTagInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index].value = value;
    setInputList(list);
  };

  const renderTagValue = (tag, index) => {
    const { client } = props.client;
    if (tag.type === "array") {
      return (
        <CategoryDropdown
          className="image-modal-input"
          placeholder="Search"
          label={"Value"}
          key={tag.name}
          name={tag.name}
          id={tag.name}
          type={"applySelectedTag"}
          item={{}}
          options={[]}
          selected={[]}
          setSelectedTagValue={(value) => {
            const list = [...inputList];
            list[index].value = value;
            setInputList(list);
          }}
          client={client}
        />
      );
    } else if (tag.type === "bool") {
      return (
        <div className="selection-group">
          <div className="single-select">
            <input
              name="single-select-input"
              type="radio"
              id="updateImageYes"
              onChange={() => {
                const list = [...inputList];
                list[index].value = "true";
                setInputList(list);
              }}
            />
            <label htmlFor="updateImageYes">Yes</label>
            <input
              defaultChecked
              name="single-select-input"
              type="radio"
              id="updateImageNo"
              onChange={() => {
                const list = [...inputList];
                list[index].value = "false";
                setInputList(list);
              }}
            />
            <label htmlFor="updateImageNo">No</label>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "20px" }}>
          <label className="form-label">Value:</label>
          <input
            type="text"
            name="value"
            id="value"
            value={tag.value || ""}
            onChange={(e) => {
              handleSelectedTagInputChange(e, index);
            }}
          />
        </div>
      );
    }
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    setInputList([...inputList, { name: "", value: "", type: "" }]);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const userRoles = getUserRoles(props.user);
  const isExternalDA = userRoles
    ? userRoles.toString() === ROLE.EXTERNAL_DATA_ANALYST
    : false;

  const classes = cn("modal image-tags-modal noselect", {
    "is-visible": props.show,
  });
  return (
    <div className={classes}>
      <div className="modal-container">
        <div className="modal-close">
          <button
            id="closeModal"
            onClick={() => {
              handleOnClose();
            }}
          >
            <i className="fe fe-x"></i>
          </button>
        </div>

        <div className="modal-content">
          <div className="row">
            <div className={!props.readOnly ? "col-md-7" : "col-md-12"}>
              <div className="modal-section-title">
                <h4>Image tags:</h4>
              </div>
              {deleteErrors.length > 0 && (
                <Alert type={"danger"}>
                  {deleteErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}
              <div className="tag-list">
                {updateTagErrors.length > 0 && (
                  <Alert type={"danger"}>
                    {updateTagErrors.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </Alert>
                )}
                {updateTagResponseMessage && (
                  <Alert type={"success"}>
                    <span>{updateTagResponseMessage}</span>
                  </Alert>
                )}

                {deleteErrors.length > 0 && (
                  <Alert type={"danger"}>
                    {deleteErrors.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </Alert>
                )}
                {deleteResponseMessage && (
                  <Alert type={"success"}>
                    <span>{deleteResponseMessage}</span>
                  </Alert>
                )}
                {renderCurrentTags()}
              </div>
            </div>
            {!props.readOnly && (
              <div className="col-md-5">
                {toggleForm && (
                  <div>
                    <div className="modal-section-title">
                      <h4>Create tag:</h4>
                      <p className="pull-text-right">
                        <span
                          className="toggle-forms-btn noselect"
                          onClick={() => {
                            handleToggleForm();
                          }}
                        >
                          Choose existing
                        </span>
                      </p>
                    </div>
                    {createErrors.length > 0 && (
                      <Alert type={"danger"}>
                        {createErrors.map((item, index) => (
                          <span key={index}>{item}</span>
                        ))}
                      </Alert>
                    )}
                    {createResponseMessage && (
                      <Alert type={"success"}>
                        <span>{createResponseMessage}</span>
                      </Alert>
                    )}
                    <AddTagsForm onSubmit={handleCreate} id="addForm" />
                  </div>
                )}

                {!toggleForm && (
                  <div>
                    <div className="modal-section-title">
                      <h4>Choose tag:</h4>
                      {!isExternalDA && (
                        <p className="pull-text-right">
                          <span
                            className="toggle-forms-btn noselect"
                            onClick={() => {
                              handleToggleForm();
                            }}
                          >
                            Create tag
                          </span>
                        </p>
                      )}
                    </div>

                    {applyTagErrors.length > 0 && (
                      <Alert type={"danger"}>
                        {applyTagErrors.map((item, index) => (
                          <span key={index}>{item}</span>
                        ))}
                      </Alert>
                    )}
                    {applyTagResponseMessage && (
                      <Alert type={"success"}>
                        <span>{applyTagResponseMessage}</span>
                      </Alert>
                    )}
                    <form autoComplete="off" id="chooseExistingTagForm">
                      <div className="form-section">
                        {inputList.map((input, idx) => {
                          return (
                            <div className="input-box" key={idx}>
                              {idx > 0 && (
                                <button
                                  className="remove-tag-btn"
                                  onClick={(e) => handleRemoveClick(e, idx)}
                                >
                                  <i className="fe fe-trash-2"></i>
                                </button>
                              )}
                              <label className="form-label">Name:</label>
                              <select
                                className="select-dropdown"
                                value={input.name}
                                onChange={(e) => handleSelectTag(e, idx)}
                              >
                                {tags.map((item, index) => {
                                  return (
                                    <option key={index} value={item.value}>
                                      {item.display}
                                    </option>
                                  );
                                })}
                              </select>
                              {!isEmptyString(input.name) &&
                                renderTagValue(input, idx)}
                            </div>
                          );
                        })}
                      </div>
                      {inputList.length > 0 && (
                        <span
                          className="apply-more-tags-btn noselect"
                          onClick={handleAddInput}
                        >
                          Apply more
                        </span>
                      )}
                      <div className="form-footer">
                        <button
                          type="submit"
                          onClick={(e) => {
                            handleApplyTag(e);
                          }}
                          className="btn btn-success btn-block"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
