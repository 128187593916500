import React from "react";
import VirtualList from "react-tiny-virtual-list";
import ImageItem from "./../ImageItem";
import { SelectableGroup, createSelectable } from "react-selectable";
import * as cn from "classnames";

const SelectableImageItem = createSelectable(ImageItem);

const ImageList = ({
  height,
  items,
  selected,
  handleSelectImage,
  openImageModal,
  show,
  openLightBox,
  handleSelection,
  tolerance,
  selectOnMouseMove,
  handleWatchedVideo,
}) => {
  const classes = cn("images", { show: show });

  return (
    <SelectableGroup
      className="images-container"
      onSelection={handleSelection}
      tolerance={tolerance}
      selectOnMouseMove={selectOnMouseMove}
    >
      <VirtualList
        id="images"
        className={classes}
        width="100%"
        height={height}
        itemCount={items.length >= 3 ? Math.ceil(items.length / 3) : 1}
        itemSize={340}
        renderItem={({ index, style }) => {
          return (
            <div className="row" style={style} key={index++}>
              {items
                .slice(
                  (index === 0 ? index++ : index - 1) * 3,
                  index === 0 ? index++ : index * 3
                )
                .map((item, itemIndex) => {
                  let imageIndex = items
                    .map((e) => {
                      return e._id;
                    })
                    .indexOf(item._id);
                  return (
                    <div className="col-md-4" key={itemIndex}>
                      <SelectableImageItem
                        selectableKey={item._id}
                        key={item._id}
                        images={items}
                        image={item}
                        selected={selected}
                        onChange={handleSelectImage}
                        onClick={openImageModal}
                        openLightBox={openLightBox}
                        imageIndex={imageIndex}
                        handleWatchedVideo={handleWatchedVideo}
                      />
                    </div>
                  );
                })}
            </div>
          );
        }}
      />
    </SelectableGroup>
  );
};

export default ImageList;
