import { useState, useEffect } from 'react';
import { useAuth0 } from '../../context/auth-context';

const useImgAuth = (url) => {
  const [imgUrl, setImgUrl] = useState('');
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getTokenSilently().then((token) => {
      if (url) {
        fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            setImgUrl(URL.createObjectURL(blob));
          })
          .catch((err) => console.error('Error fetching image', err));
      }
    });
  }, [url, getTokenSilently]);

  return imgUrl;
};

export default useImgAuth;
