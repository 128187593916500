import React from "react";
import Icon from "../Icon";
import { connect } from "react-redux";

import { ClientActions, MainActions, SidebarActions } from "actions";
import { CommonService } from "services";
import ROLE from "utils/enums/role";
import { isEmpty } from "utils/helpers/object";
import { getUserRoles } from "utils/helpers/getUserRoles";

import "./style.css";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
      serviceType: {},
      showServiceTypeList: false,
      showFilters: false,
      types: [],
    };
    this.openSidebar = this.openSidebar.bind(this);
    this.openTags = this.openTags.bind(this);
  }

  renameProperty(obj, oldName, newName) {
    obj[newName] = obj[oldName];
    delete obj[oldName];
    return obj;
  }

  async componentDidMount() {
    const { user } = this.props;
    const { serviceType } = this.props.main;

    const userRoles = getUserRoles(user);

    const isExternalDA = userRoles
      ? userRoles.toString() === ROLE.EXTERNAL_DATA_ANALYST
      : false;

    const reference = {
      columnName: "type",
      tableName: "service",
    };

    const types = await CommonService.getReferenceLookup(reference);

    if (!types.errors) {
      const mappedTypes = isExternalDA
        ? types.responseList
            .filter((item) => item.name.toLowerCase() !== "check:scan")
            .map((item) => this.renameProperty(item, "name", "display"))
        : types.responseList.map((item) =>
            this.renameProperty(item, "name", "display")
          );
      this.setState({
        types: mappedTypes,
      });
    }

    if (!isEmpty(user) && user) {
      this.setState(
        {
          user,
        },
        async () => {
          if (!isEmpty(serviceType)) {
            this.setState({
              serviceType,
            });
          }

          if (
            userRoles &&
            userRoles.some((role) => {
              return (
                role === ROLE.ADMINISTRATOR || role === ROLE.DATA_ANALYST_ADMIN || role === ROLE.DATA_ANALYST
              );
            })
          ) {
            this.setState({
              showFilters: true,
            });
          }
        }
      );
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.main.serviceType !== prevProps.main.serviceType ||
      this.props.user !== prevProps.user
    ) {
      this.setState({
        serviceType: this.props.main.serviceType,
        user: this.props.user,
      });
    }
  }

  openSidebar() {
    this.props.open();
    // Hide scrollbar if sidebar is opened
    document.body.style.overflowY = "hidden";
  }

  openTags() {
    this.props.openTags();
  }

  toggleServiceType() {
    this.setState({
      showServiceTypeList: !this.state.showServiceTypeList,
    });
  }

  async selectServiceType(type) {
    this.props.setServiceType(type);
    this.setState({
      showServiceTypeList: false,
    });
  }

  render() {
    const { user, types, showServiceTypeList } = this.state;
    return (
      // App header starts here
      user ? (
        <div className="app-header noselect">
          <div className="top-bar">
            <div className="container flex-container flex-space-between">
              <div className="app-logo">
                <span>ptag</span>
              </div>
              {
                <div className="user-profile flex-box flex-align-items-center">
                  <div className="user-info">
                    <span>{user.nickname}</span>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="logout"
                    onClick={() => this.props.logout()}
                  >
                    <Icon name="log-out" />
                  </a>
                </div>
              }
            </div>
          </div>
          <div className="service-type-filters-bar">
            <div className="container main-container">
              <div>
                <div className="flex-container flex-space-between flex-align-items-center">
                  {user.roles &&
                  user.roles.some((role) => {
                    return role.value === ROLE.DATA_ANALYST;
                  }) ? (
                    <div className="current-service-type">
                      {this.props.main.serviceType ? (
                        <h4>
                          Current service type:{" "}
                          <strong>{this.props.main.serviceType.display}</strong>
                        </h4>
                      ) : null}
                    </div>
                  ) : (
                    <div className="service-type-list">
                      <div className="select-box">
                        <span>
                          {this.props.main.serviceType
                            ? this.props.main.serviceType.display
                            : "Select type"}
                        </span>
                        <span
                          className="down-icon"
                          onClick={() => this.toggleServiceType()}
                        >
                          <Icon name="chevron-down" />
                        </span>
                      </div>
                      {showServiceTypeList && (
                        <div className="service-type-list-dropdown">
                          <ul>
                            {types &&
                              types.map((item, idx) => {
                                return (
                                  <li
                                    key={idx}
                                    onClick={() => this.selectServiceType(item)}
                                  >
                                    <span>{item.display}</span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="flex-box flex-align-items-center">
                    <div className="tags-filters-btn-group flex-box">
                      <div className="create-tags-modal">
                        <button
                          className="open-tags-modal tooltip-bottom btn"
                          data-tooltip="Manage tags"
                          disabled={!this.props.main.serviceType}
                          onClick={this.openTags}
                        >
                          <Icon name="tag" />
                        </button>
                      </div>
                      <div className="show-filters">
                        <button
                          id="openSidebar"
                          className="open-filters-sidebar tooltip-bottom btn"
                          disabled={
                            !this.props.main.serviceType ||
                            !this.state.showFilters
                          }
                          data-tooltip="Filters"
                          onClick={this.openSidebar}
                        >
                          <Icon name="sliders" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )
      // App header ends here
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  main: state.main,
});

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(SidebarActions.open()),
  openTags: () => dispatch(MainActions.openTags()),
  getClients: () => dispatch(ClientActions.list()),
  setClient: (client) => dispatch(ClientActions.setClient(client)),
  setServiceType: (type) => dispatch(MainActions.setServiceType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
