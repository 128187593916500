import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const defaultId = 'default-alert';

const messageSubject = new Subject();

const MESSAGE_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARNING: 'WARNING'
}

class ToastNotificationService {
    // enable subscribing to messages observable
    static onMessage(id = defaultId) {
        return messageSubject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    static success(subject, message, autoClose = true, keepAfterRouteChange = false) {
        this.message({ type: MESSAGE_TYPE.SUCCESS, message, subject, autoClose, keepAfterRouteChange });
    }

    static error(subject, message, autoClose = true, keepAfterRouteChange = false) {
        this.message({ type: MESSAGE_TYPE.ERROR, message, subject, autoClose, keepAfterRouteChange });
    }

    static info(subject, message, autoClose = true, keepAfterRouteChange = false) {
        this.message({ type: MESSAGE_TYPE.INFO, message, subject, autoClose, keepAfterRouteChange });
    }

    static warn(subject, message, autoClose = true, keepAfterRouteChange = false) {
        this.message({ type: MESSAGE_TYPE.WARNING, message, subject, autoClose, keepAfterRouteChange });
    }

    // core message method
    static message(message) {
        message.id = message.id || defaultId;
        messageSubject.next(message);
    }

    // clear messages
    static clear(id = defaultId) {
        messageSubject.next({ id });
    }
}

export default ToastNotificationService;