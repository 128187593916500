export const getDeepestObject = (obj) => {
    const keys = Object.keys(obj);
    const [key] = keys;
    if (keys && keys.length === 1 && typeof obj[key] === "object") {
        if(obj[key]){ 
            if (obj[key].length !== undefined) {
                return obj[key];
            }
            return getDeepestObject(obj[key]);
        }
    }
    return obj;
};
  
  
export const isEmpty = (obj) => {
    for(const prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
};