import {connect} from 'react-redux';
import Sidebar from './Sidebar';
import { SidebarActions, MainActions } from '../../../../actions';

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  client: state.client,
  main: state.main,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(SidebarActions.close()),
  setFilter: (filter) => dispatch(MainActions.setFilter(filter)),
  tagsChanged: (flag) => dispatch(MainActions.tagsChanged(flag))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
