import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from 'store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import LineLoader from './components/LineLoader';
import {usePromiseTracker} from "react-promise-tracker";


// Styles
import 'assets/stylesheets/reset.css';
import 'assets/stylesheets/feather.css';
import 'assets/stylesheets/style.css';
import { Auth0Provider } from './context/auth-context';
import history from './store/history';

const audience = process.env.REACT_APP_AUDIENCE_URL;
const clientId = process.env.REACT_APP_CLIENT;
const domain = process.env.REACT_APP_AUTH_DOMAIN;

const store = configureStore();

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const Loader = () => {
    const {promiseInProgress} = usePromiseTracker();
    return (
        promiseInProgress &&
        <LineLoader />
    );
  };

ReactDOM.render(
    <Provider store={store}>
        <Auth0Provider
            domain={domain}
            client_id={clientId}
            redirect_uri={window.location.origin}
            audience={audience}
            useRefreshTokens={true}
            onRedirectCallback={onRedirectCallback}
        >
            <BrowserRouter history={history}>
                <App />
                <Loader />
            </BrowserRouter>
        </Auth0Provider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
