import * as JOB_TYPES from '../types';

const initialState = {
    jobId: '',
    jobType: '',
    subType: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case JOB_TYPES.SET_JOB_ID: {
            return {
                ...state,
                jobId: action.payload.jobId
            }
        }
        case JOB_TYPES.SET_JOB_TYPE: {
            return {
                ...state,
                jobType: action.payload.jobType
            }
        }
        case JOB_TYPES.SET_SUB_TYPE: {
            return {
                ...state,
                subType: action.payload.subType
            }
        }
        default:
            return state;
    }
}