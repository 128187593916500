import React, { Component } from 'react';
import * as cn from 'classnames';
import Icon from './../../../../components/Icon';
import onClickOutside from "react-onclickoutside";

const isEmptyString = (str) => {
    return (!str || str.length === 0);
}

class CategoryDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selected: [],
            searchInputValue: '',
            suggestions: []
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAppendToTags = this.handleAppendToTags.bind(this);
    }

    componentDidMount() {
        this.setState({
            selected: this.props.selected
        })
    }

    componentWillUnmount = () => {
        this.setState({
            isOpen: false,
            selected: [],
        })
    }
    
    toggleDropdown() {
        this.setState({
            isOpen: !this.state.isOpen,
            searchInputValue: "",
            suggestions: []
        })
    }
    
    handleAppendToTags(e, id, name, item) {
        e.preventDefault();
        e.stopPropagation();
        let currentSelected = this.state.selected;

        if(!currentSelected.includes(item))
            currentSelected.push(item);

        this.setState({selected: currentSelected}, () => {
            if(this.props.type === "updateFilters")
                this.props.updateFilters(id, this.state.selected, name)
            else if(this.props.type === "applySelectedTag")
                this.props.setSelectedTagValue(this.state.selected.toString())
            else if(this.props.type === "updateFileInput")
                this.props.updateTagValue(name, this.state.selected.toString())
        });
    }

    handleRemoveToTags(e, item) {
        e.preventDefault();
        e.stopPropagation();
        let currentSelected = this.state.selected;

        if(currentSelected.includes(item))
            currentSelected.splice(currentSelected.indexOf(item), 1);

        this.setState({selected: currentSelected, searchInputValue: '', suggestions: []}, () => {
            if(this.props.type === "updateFilters")
                this.props.updateFilters(this.props.id, this.state.selected, this.props.name)
            else if(this.props.type === "updateFileInput")
                this.props.updateTagValue(this.props.name, this.state.selected)
        });
    }
    
    handleInputChange = async (event) => {
        this.setState({
            searchInputValue: event.target.value.trim()
        });
    }

    renderSuggestions = () => {
        const { suggestions, searchInputValue } = this.state;
        if(suggestions.length === 0)
            return null;
        
        return (
            suggestions.map((item, index) => {
                if(item.value.substr(0, searchInputValue.length).toUpperCase() === searchInputValue.toUpperCase()){
                    return (
                        <div key={index} onClick={(e) => {this.handleAppendToTags(e, this.props.id, this.props.name, item.value)}}>
                            <p><strong>{item.value.substr(0, searchInputValue.length)}</strong>{item.value.substr(searchInputValue.length)}</p>
                        </div>
                    )
                }
            })
        )
    }

    handleClickOutside = () => {
        this.setState({
            isOpen: false,
            searchInputValue: ""
        });
    }

    render() {
        const {placeholder, size, name, className, label} = this.props;
        const {isOpen, suggestions, selected} = this.state;
        const classes = cn(className, size ? `${"col-md"}-${size}` : "", "category-dropdown-container form-input-single");
        return (
            <div className={classes}>
                {(this.props.type != "updateFileInput" && this.props.type != "applyTagInput") && label && <label className="form-label">{label}:</label>}
                <button type="button" className="category-dropdown-toggle" onClick={() => this.toggleDropdown()}>
                    <ul>
                        {selected && selected.length > 0 && selected.map((item, idx) => {
                                return(
                                    <li key={idx}>
                                        <span className="tag">{item.replace(/[\[\]']+/g,'')}
                                            <span className="tag-addon" onClick={(e) => this.handleRemoveToTags(e, item)}>x</span>
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </button>
                <div className={cn(isOpen ? "show" : "", "category-dropdown")}>
                    <div className="form-input-single">
                        <input type="text" data-id={this.props.name} name={this.props.name} value={this.state.searchInputValue} id={this.props.name} placeholder={placeholder} onChange={(e) => this.handleInputChange(e)} autoComplete="off" />
                        {!isEmptyString(this.state.searchInputValue) && <span className="add-new-value" onClick={(e) => {this.handleAppendToTags(e, this.props.id, this.props.name, this.state.searchInputValue)}}>
                            <Icon name="plus-circle" />
                        </span>}
                    </div>
                </div>
            </div>
        )
    }
}

export default onClickOutside(CategoryDropdown);