import * as MAIN_TYPES from "../types";
import ImageService from "../services/ImageService";
import imageListFormatter from "../utils/helpers/imageListFormatter";

class MainActions {
  // Set service type
  static setServiceType(type) {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.SET_SERVICE_TYPE,
        payload: { serviceType: type },
      });
    };
  }

  // Getting images - For initial loading
  static getImages(
    client,
    filter = "",
    isOther = false,
    limit = 40,
    serviceType
  ) {
    return async (dispatch) => {
      // Destructuring Nested Objects
      // client, filterString = '', isOther = false, limit = 40, next = "", serviceType
      const { images, next } = await ImageService.getFiles(
        client,
        filter,
        isOther,
        limit,
        "",
        serviceType
      );
      return await dispatch({
        type: MAIN_TYPES.GET_IMAGES,
        payload: {
          images: imageListFormatter(images),
          next,
        },
      });
    };
  }

  // Getting images - Get more images
  static getMoreImages(
    client,
    filter,
    isOther = false,
    limit,
    nextPage,
    serviceType
  ) {
    return async (dispatch) => {
      // Destructuring Nested Objects
      const { images, next } = await ImageService.getFiles(
        client,
        filter,
        isOther,
        limit,
        nextPage,
        serviceType
      );

      return await dispatch({
        type: MAIN_TYPES.GET_MORE_IMAGES,
        payload: {
          images: imageListFormatter(images),
          next,
        },
      });
    };
  }

  // After successful request for deleting images, delete images from redux (home.images)
  static deleteImages(ids) {
    return async (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.DELETE_IMAGES,
        payload: ids,
      });
    };
  }

  // Open image modal with current tags for selected image and set isOpen to true
  static open = (id) => {
    return async (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.OPEN_IMAGE_MODAL,
        payload: { id: id, show: true },
      });
    };
  };

  // Close image modal with empty tags array and set isOpen to false
  static close = () => {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.CLOSE_IMAGE_MODAL,
        payload: { id: "", show: false },
      });
    };
  };

  // Open tags modal
  static openTags = () => {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.OPEN_TAGS,
        payload: { showCreateTags: true },
      });
    };
  };

  // Close tags modal
  static closeTags = () => {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.CLOSE_TAGS,
        payload: { showCreateTags: true },
      });
    };
  };

  // Tags changed
  static tagsChanged = (flag) => {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.TAGS_CHANGED,
        payload: flag,
      });
    };
  };

  // After successful request for updating images, update images in redux (home.images)
  static updateFiles(ids) {
    return async (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.UPDATE_FILES,
        payload: ids,
      });
    };
  }

  // Set filter string for get images action
  static setFilter = (filter) => {
    return (dispatch) => {
      return dispatch({
        type: MAIN_TYPES.SET_FILTER,
        payload: { filter: filter },
      });
    };
  };
}

export default MainActions;
