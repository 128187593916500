import React from 'react';

import './style.css';

const LineLoader = () => {
  return (
    <div className="linear-loader">
        <div className="indeterminate"></div>
    </div>
  );
};

export default LineLoader;
