import moment from "moment";

export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:MM:ss";

export const getDateTimeString = (date, time) => moment(date).format(DATE_FORMAT).toString() + "T" + moment(time).format(TIME_FORMAT).toString();

export const convertDateTimeToString = (date) => moment(date).format(DATE_FORMAT).toString() + "T" + moment(date).format(TIME_FORMAT).toString();

export const parseISOString = (s) => {
  const b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}