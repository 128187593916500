import GraphQL from "../../utils/graphql";
import { GET_FILES, GET_TAGS, DELETE_FILES, AUTOCOMPLETE, CREATE_TAGS, DELETE_TAG, DELETE_TAG_ON_FILE, UPDATE_FILE, PTAG_DONE } from '../../graphql/schemas';

class ImageService {
  static async getFiles(client, filterString = '', isOther = false, limit = 40, next = "", serviceType){
    
    const data = await GraphQL.query({
      query: GET_FILES,
      variables: {
        input: {
            client: isOther ? "" : client,
            serviceType: serviceType,
            filter: {
                filter: filterString,
                limit: limit,
                includeCount: false,
                next: next
            }
        }
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        next: '',
        images: []
      }
    }
    else{
      const response = {};
      const files = data;

      for (const key of Object.keys(files)) {
        response[key] = !files[key] ? {} : files[key]
      }
      
      return {
        next: response.next,
        images: !response.vfsObjects.objects ? [] : response.vfsObjects.objects,
        errors: null
      }
    }
  }

  static async getTags(client, serviceType){
    const data = await GraphQL.query({
      query: GET_TAGS,
      variables: {
        input: {
          serviceType: serviceType,
          client: client
        }
      }
    })

    if(data.errors){
      return {
        fields: [],
        errors: data.errors
      }
    }
    else{
      const response = {};
      const tags = data;
    
      for (const key of Object.keys(tags)) {
        response[key] = !tags[key] ? {} : tags[key]
      }
      
      return {
        fields:  !response.vfsTag.fields ? [] : response.vfsTag.fields,
        errors: ''
      }
    }
  }

  static async getTagsById(client, id, serviceType){
    const filter = `_id=${id}`;

    const data = await GraphQL.query({
      query: GET_TAGS,
      variables: {
        input: {
          client: client,
          serviceType: serviceType,
          filter: {
            filter: filter
          }
        }
      }
    })

    if(data.errors){
      return {
        fields: [],
        errors: data.errors
      }
    }
    else{
      const response = {};
      const tags = data.getTags;

      for (const key of Object.keys(tags)) {
        response[key] = !tags[key] ? {} : tags[key]
      }
      
      return {
        fields: !response.vfsTag.fields ? [] : response.vfsTag.fields,
        errors: null
      }
    }
  }

  static async deleteFiles(client, ids, serviceType){
    const data = await GraphQL.mutation({
      mutation: DELETE_FILES,
      variables: {
        input: {
          client: client,
          serviceType: serviceType,
          message: ids
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      const { deleteFiles: { message } = {} } = data;
      return {
        success: message ? true : false,
        message: "Selected images have been successfully deleted.",
        errors: null
      }
    }
  }

  static async autoComplete(client, filterString){
    const data = await GraphQL.query({
      query: AUTOCOMPLETE,
      variables: {
        input: {
            client: client,
            filter: {
                filter: filterString
            }
        }
      }
    })

    if(data.errors){
      return {
        errors: data.errors,
        values: []
      }
    }
    else{
      const values = data.autoComplete.acTagValuesResponse.acTagValuesResponses;
      const response = [];

      if(values){
        values.map(item => {
          response.push({
            value: item.tagValue
          })
        })
      }
      
      return {
        errors: null,
        values: response
      }
    }
  }

  static async createTags(client, tags = [], serviceType){
    const data = await GraphQL.mutation({
      mutation: CREATE_TAGS,
      variables: {
        input: {
          serviceType: serviceType,
          client: client,
          tag :{
            definitions : tags
          }
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      const { createTags: { message } = {} } = data;
      this.getTags(client, serviceType);
      return {
        success: message ? true : false,
        message: "Tag has been successfully created.",
        errors: null
      }
    }
  }

  static async deleteTag(client, name, serviceType){
    const data = await GraphQL.mutation({
      mutation: DELETE_TAG,
      variables: {
        input: {
          client: client,
          serviceType: serviceType,
          message: name
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      const { deleteTags: { message } = {} } = data;
      return {
        success: message ? true : false,
        message: "Tag has been successfully deleted.",
        errors: null
      }
    }
  }

  static async deleteTagOnFile(client, id, name, serviceType){
    const data = await GraphQL.mutation({
      mutation: DELETE_TAG_ON_FILE,
      variables: {
        input: {
          serviceType: serviceType,
          client: client,
          tagValues: {
            key: name,
            value: ""
          },
          message: id
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      return {
        success: true,
        message: "Tag has been successfully deleted.",
        errors: null
      }
    }
  }

  static async updateFile(client, tags = "", ids, serviceType, updateStrategy = ""){
    const data = await GraphQL.mutation({
      mutation: UPDATE_FILE,
      variables: {
        input: {
          serviceType: serviceType,
          client: client,
          tagValues: tags,
          message: ids,
          updateStrategy
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      const { updateFile: { message } = {} } = data;
      return {
        success: message ? true : false,
        message: "Successfully updated.",
        errors: null
      }
    }
  }

  static async ptagDone(client, paths, jobId, jobType, imageCounter, filter, user){
    const data = await GraphQL.mutation({
      mutation: PTAG_DONE,
      variables: {
        input: {
          client: client,
          filter: filter,
          links: paths,
          jobId: jobId,
          jobType: jobType,
          imageCounter: imageCounter,
          user: user
        }
      }
    }) 

    if(data.errors){
      return {
        success: false,
        errors: data.errors,
      }
    }
    else{
      const { ptagDone: { message } = {} } = data;
      return {
        success: message ? true : false,
        message: "Selected images have been sent for preparing task.",
        errors: null
      }
    }
  }
}

export default ImageService;