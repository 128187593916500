import * as decodeJwt from 'jwt-decode';
import { ROLE_MAPPER } from './../enums/role';
const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const loginFormatter = (response, credentials = {}) => {
  if (!response.errors && response) {
    const {authenticationResult, challengeName, roles, scopes} = response;

    if (challengeName && challengeName === NEW_PASSWORD_REQUIRED) {
      return  {
        username: credentials.username,
        firstTimeLogin: true,
        session: response.session
      }
    }
    else {
      const {accessToken, idToken, refreshToken} = authenticationResult;
      const {username} = decodeJwt(accessToken);
      const {email} = decodeJwt(idToken);
      let userRoles = roles && roles.map(item => {
        return {
          id: item.id,
          created: item.created,
          ...ROLE_MAPPER[item.name]
        };
      });

      return {
        accessToken,
        refreshToken,
        username,
        email,
        roles: userRoles,
        scopes,
        group: response.group
      };
    }
    
  }
  return response;
};

export default loginFormatter;