import React from 'react';
import * as cn from 'classnames';

function Icon({
  prefix: prefixFromProps = "fe",
  name,
  className,
  link = false,
  isAriaHidden,
  payment,
  flag,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onFocus,
  onBlur
}){
  const prefix = (payment && "payment") || (flag && "flag") || prefixFromProps;
  const classes = cn(
    {
      [prefix]: true,
      [`${prefix}-${name}`]: true,
    },
    className
  );
  const extraProps = isAriaHidden
    ? {
        "aria-hidden": "true",
      }
    : null;

  const eventProps = {
    onClick,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    onFocus,
    onBlur,
  };

  return !link ? (
    <i className={classes} {...eventProps} />
  ) : (
    <a className="icon" {...extraProps} {...eventProps}>
      <i className={classes} />
    </a>
  );
}

export default Icon;