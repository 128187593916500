import * as CLIENT_TYPES from '../types';
import {ClientService} from './../services';

class ClientActions {
  static list() {
    return async (dispatch) => {
      const response = await ClientService.list();

      return await dispatch({
        type: CLIENT_TYPES.GET_CLIENTS,
        payload: {list: !response.getAllCompanies ? [] : response.getAllCompanies }
      });
    }
  }
  // Set client 
  static setClient(client){
    return (dispatch) => {
      return dispatch({
        type: CLIENT_TYPES.SET_CLIENT,
        payload: { client: client }
      });
    }
  }
}

export default ClientActions;