import GraphQL from "../../utils/graphql";
import { GET_REFERENCE_LOOKUP } from '../../graphql/schemas';

class CommonService {
    static async getReferenceLookup(input){
        return await GraphQL.query({
            query: GET_REFERENCE_LOOKUP,
            variables: {
                input: input
            }
        });
    }
}

export default CommonService;
