const tagsFormatter = (images) => {
    const tags = [];

    images.map((image) => {
        const { data } = image;
        data.map(item => {
            if(item.stringValue){
                tags.push({
                    name: item.key,
                    value: item.stringValue,
                    type: "string"
                })
            }

            else if(item.numberValue){
                tags.push({
                    name: item.key,
                    value: item.numberValue,
                    type: "int"
                })
            }

            else if(item.booleanValue){
                tags.push({
                    name: item.key,
                    value: item.booleanValue.toString(),
                    type: "bool"
                })
            }
            
            else if(item.listValue){
                tags.push({
                    name: item.key,
                    value: item.listValue.toString(),
                    type: "array"
                })
            }
        })
    })

    return tags;
}

export default tagsFormatter;