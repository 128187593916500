import React, { useState, useEffect } from 'react';
import Icon from '../../../../components/Icon';
import usePrevious from './../../../../utils/helpers/usePrevious';
import useToggle from '../../../../utils/helpers/useToggle';
import ClickOutside from '../../../../utils/helpers/clickOutside';

const DynamicInput = ({item, id, updateFilters, updateOperators}) => {
    const [input, setInput] = useState(item);
    const [showOperators, setShowOperators] = useToggle(false);
    const [operator, setOperator] = useState("=");

    const operators = ["=", ">", "<", ">=", "<="];

    useEffect(() => {
        setInput(item);
    }, []);

    const handleShowOperators = () => {
        setShowOperators();
    }

    const handleSelectOperator = (operator) => {
        setOperator(operator);
        updateOperators(id, operator);
    }

    const handleOnChange = (e) => {
        updateFilters(id, e.target.value.trim(), e.target.name)
    }

    return (
        <div className="form-input-group col-md-12">
            <label className="form-label">{input.name}: </label>
            <div className="input-group">
                <span className="input-group-prepend"  onClick={handleShowOperators}>
                    <span className="input-group-text">
                        {operator}
                    </span>

                    {
                        showOperators && <div className="operators">
                            <ul>
                                {
                                    operators.map((operator, index) => {
                                        return (<li key={index} onClick={() => {handleSelectOperator(operator)}}>{operator}</li>)
                                    })
                                }
                            </ul>
                        </div>
                    }
                </span>
                <input type="text" data-id={id} name={input.name} value={input.value} id={input.name} onChange={(e) => { handleOnChange(e) }} autoComplete="off" />
            </div>
        </div>
    )
}

export default DynamicInput;