const ROLE = {
  ADMINISTRATOR: "ADMINISTRATOR",
  DATA_ANALYST: "DATA_ANALYST",
  PROJECT_MANAGER: "PROJECT_MANAGER",
  EXTERNAL_DATA_ANALYST: "EXTERNAL_DATA_ANALYST",
  DATA_ANALYST_ADMIN: "DATA_ANALYST_ADMIN",
};

export const ROLE_MAPPER = {
  ADMINISTRATOR: {
    name: "Administrator",
    value: ROLE.ADMINISTRATOR,
  },
  DATA_ANALYST: {
    name: "Data Analyst",
    value: ROLE.DATA_ANALYST,
  },
  PROJECT_MANAGER: {
    name: "Project Manager",
    value: ROLE.PROJECT_MANAGER,
  },
  EXTERNAL_DATA_ANALYST: {
    name: "External Data Analyst",
    value: ROLE.EXTERNAL_DATA_ANALYST,
  },
};

export const ROLES = [
  {
    name: "Administrator",
    value: ROLE.ADMINISTRATOR,
  },
  {
    name: "Data Analyst",
    value: ROLE.DATA_ANALYST,
  },
  {
    name: "Project Manager",
    value: ROLE.PROJECT_MANAGER,
  },
  {
    name: "External Data Analyst",
    value: ROLE.EXTERNAL_DATA_ANALYST,
  },
];
export default ROLE;
