import * as JOB_TYPES from '../types';

class JobActions {
  // Set job id
  static setJobId(id) {
    return (dispatch) => {
      return dispatch({
        type: JOB_TYPES.SET_JOB_ID,
        payload: { jobId: id }
      });
    }
  }
   // Set job type
   static setJobType(type) {
    return (dispatch) => {
      return dispatch({
        type: JOB_TYPES.SET_JOB_TYPE,
        payload: { jobType: type }
      });
    }
  }
  // Set job subtype
  static setSubType(type) {
    return (dispatch) => {
      return dispatch({
        type: JOB_TYPES.SET_SUB_TYPE,
        payload: { subType: type }
      });
    }
  }
}

export default JobActions;