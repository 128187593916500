
import React, { useEffect } from 'react';
import { useAuth0 } from '../../../../context/auth-context';

const Login = () => {
  const {loginWithRedirect} = useAuth0();

  useEffect(() => {
    redirectToLoginPage();
  }, [])


  const redirectToLoginPage = () => {
    loginWithRedirect();
  }

  return (
     <div></div>
  );
}
export default Login;