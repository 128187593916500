import * as CLIENT_TYPES from '../types';

const initialState = {
    list: [],
    client: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_TYPES.GET_CLIENTS: {
            return {
                ...state,
                list: action.payload.list
            }
        }
        case CLIENT_TYPES.SET_CLIENT: {
            return {
                ...state,
                client: action.payload.client
            }
        }
        default:
            return state;
    }
}