import {loader} from 'graphql.macro';

// users
export const SIGN_IN = loader('./user/signIn.graphql');
export const CHANGE_INITIAL_PASSWORD = loader('./user/changeInitalPassword.graphql');
export const FORGOT_PASSWORD = loader('./user/forgotPassword.graphql');
export const RESET_PASSWORD = loader('./user/resetPassword.graphql');
export const GET_ACCESS_TOKEN = loader('./user/getAccessToken.graphql');
export const SIGN_OUT = loader('./user/signOut.graphql');

// tags
export const GET_TAGS = loader('./tags/getTags.graphql');
export const AUTOCOMPLETE = loader('./tags/autoComplete.graphql');
export const CREATE_TAGS = loader('./tags/createTags.graphql');
export const DELETE_TAG = loader('./tags/deleteTag.graphql');
export const DELETE_TAG_ON_FILE = loader('./tags/deleteTagOnFile.graphql');

// files
export const GET_FILES = loader('./files/getFiles.graphql');
export const UPDATE_FILE = loader('./files/updateFile.graphql');
export const DELETE_FILES = loader('./files/deleteFiles.graphql');
export const PTAG_DONE = loader('./files/ptagDone.graphql');

// Clients
export const GET_ALL_COMPANIES = loader('./clients/getAllCompanies.graphql');

// Common
export const GET_REFERENCE_LOOKUP = loader('./common/getReferenceLookup.graphql');
