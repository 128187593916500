import * as SIDEBAR_TYPES from '../types';

const initialState = {
    isOpen: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_TYPES.OPEN_SIDEBAR: {
            return {
                ...state, isOpen: true
            }
        }
        case SIDEBAR_TYPES.CLOSE_SIDEBAR: {
            return {
                ...state, isOpen: false
            }
        }
        default:
            return state;
    }
};
  
  