import React from 'react';
import * as cn from 'classnames';

import './style.css';

function Container({
    className,
    children
}){
    const classes = cn("container", className);
    return (<div className={classes}>{children}</div>)
}

export default Container;