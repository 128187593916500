import * as MAIN_TYPES from '../types';

const removeImages = (images, ids = []) => {
  return images.filter(item => !ids.includes(item._id));
}

const initialState = {
  images: [],
  next: '',
  image_modal: {
    id: '',
    show: false
  },
  showTags: false,
  filter: '',
  serviceType: undefined,
  tagsChanged: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MAIN_TYPES.GET_IMAGES: {
      return {
        ...state,
        images: action.payload.images,
        next: action.payload.next
      };
    }

    case MAIN_TYPES.GET_MORE_IMAGES: {
      const {images, next} = action.payload;
      const appendedArray = [...state.images, ...images];
      return {
        ...state,
        images: appendedArray,
        next: next
      };
    }

    case MAIN_TYPES.SET_SERVICE_TYPE: {
      return {
          ...state,
          serviceType: action.payload.serviceType
      }
    }

    case MAIN_TYPES.DELETE_IMAGES: {
      return {
        ...state,
        images: removeImages(state.images, action.payload)
      };
    }

    case MAIN_TYPES.OPEN_IMAGE_MODAL: {
      return {
        ...state,
        image_modal: action.payload
      };
    }

    case MAIN_TYPES.CLOSE_IMAGE_MODAL: {
      return {
        ...state,
        image_modal: action.payload
      };
    }

    case MAIN_TYPES.OPEN_TAGS: {
      return {
          ...state, showTags: true
      }
    }

    case MAIN_TYPES.CLOSE_TAGS: {
      return {
          ...state, showTags: false
      }
    }

    case MAIN_TYPES.TAGS_CHANGED: {
      return {
        ...state, tagsChanged: action.payload
      }
    }

    case MAIN_TYPES.SET_FILTER: {
      state.filter = "";
      return {
          ...state,
          filter: action.payload.filter
      }
    }
      
    default:
        return state;
  }
}