import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { useAuth0 } from '../../context/auth-context';

const PrivateRoute = ({
  component: Component,
  path,
  location,
  token,
  ...rest
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: location.pathname },
        });
      }
    })();
  }, [isAuthenticated, loginWithRedirect, path, location]);

  const render = (props) =>
    isAuthenticated === true ? <Component {...props} token={token} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default withRouter(PrivateRoute);
