import GraphQL from "../../utils/graphql";
import { GET_ALL_COMPANIES } from '../../graphql/schemas';

class ClientService {
  static async list() {
    return await GraphQL.query({
      query: GET_ALL_COMPANIES
    });
  }
}

export default ClientService;
