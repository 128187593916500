import React, { useState, useEffect } from 'react';

const AddTagsForm = ({onSubmit}) => {
  const [selectedTagType, setSelectedTagType] = useState('');
  const [types, setTypes] = useState([]);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    mapTagTypeToSelectList();
  }, []);

  const mapTagTypeToSelectList = () => {
    const _types = [
        {
            display: "Date and time",
            value: "datetime"
        },
        {
            display: "Number",
            value: "int"
        },
        {
            display: "Text",
            value: "string"
        },
        {
          display: "List",
          value: "array"
        },
        {
          display: "True or False",
          value: "bool"
        }
    ];

    setTypes([{value: '', display: 'Select type'}].concat(_types));
  }

  const handleSelectTagType = (event) => {
    let value = event.target.value;
    setSelectedTagType(value);
    setInputs(inputs => ({...inputs, ["type"]: value}));
  }
  
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, ["name"]: event.target.value.trim()}));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    await onSubmit(inputs);

    setInputs({});
    setSelectedTagType('');
  }

  return (
      <form id="createTagForm">
        <div className="form-section">

          <div className="form-group">
              <label className="form-field-label">Name:</label>
              <input type="text" name="name" autoComplete="off" onChange={(e) => { handleInputChange(e)}}/>
          </div>
          
          <div className="form-group">
              <label className="form-field-label">Type:</label>
              <select className="select-dropdown" name="type" value={selectedTagType} onChange={(e) => handleSelectTagType(e)}>
                  { types.map((item, index) => {
                      return <option key={index} value={item.value}>{item.display}</option>
                  })}
              </select>
          </div>
        </div>
        <div className="form-footer">
          <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)}>Submit</button>
        </div>
      </form>
    );
};

export default AddTagsForm;
