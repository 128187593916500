import React, { useState, useEffect } from 'react';
import * as cn from 'classnames';
import ImageService from '../../../../services/ImageService';
import { ToastNotificationService } from '../../../../services';

const ConfirmPtagDone = (props) => {

    const confirmPtagDone = async () => {
        const { paths, client, jobId, jobType, imageCounter, filter, user, subType } = props;
        
        const response = jobType === "CUSTOM" || jobType === "TRANSACTION" ? await ImageService.ptagDone(client, paths, jobId, subType, imageCounter, filter, user.email) : await ImageService.ptagDone(client, paths, jobId, jobType, imageCounter, filter, user.email);

        if(response.errors){
            await ToastNotificationService.error("Preparing job", response.errors[0] || "Something went wrong. Please try again!");
            handleOnClose();
        }
        else{
            await ToastNotificationService.success("Preparing job", response.message);
            handleOnClose();
        }
    }

    const handleOnClose = async () => {
        props.onClose();
    }

    const classes = cn('modal confirm-modal noselect', { 'is-visible': props.show });
    return (
        <div className={classes}>
            <div className="modal-container">
                <div className="modal-close">
                    <button id="closeModal" onClick={()=> { handleOnClose() }}>
                        <i className="fe fe-x"></i>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-section-title">
                        <h4>Confirmation</h4>
                    </div>
                    <div className="confirmation-message">
                        <p>Are you sure that you want to proceed?</p>
                    </div>
                    <div className="confirmation-btn-container">
                        <div className="btn-group">
                            <button className="cancel-btn" onClick={() => { handleOnClose() }}>Cancel</button>
                            <button className="confirm-btn confirm-create-btn" onClick={() => { confirmPtagDone() }}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPtagDone;