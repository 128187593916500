import {getDeepestObject} from "utils/helpers/object";
import {trackPromise} from "react-promise-tracker";

export default class GraphQL {
  static client;

  static init(client){
    GraphQL.client = client;
  }

  static async query(options) {
    const response = await trackPromise(GraphQL.CLIENT.query(options));
    return GraphQL.toJSON(response);
  }

  static async mutation(options) {
    const response = await trackPromise(GraphQL.CLIENT.mutate(options));
    return GraphQL.toJSON(response);
  }

  static async untrackedQuery(options) {
    const response = await GraphQL.CLIENT.query(options);
    return GraphQL.toJSON(response);
  }

  static async untrackedMutation(options) {
    const response = await GraphQL.CLIENT.mutate(options);
    return GraphQL.toJSON(response);
  }

  static toJSON(response) {
    if (response.errors) {
      return {
        errors: response.errors.map(item => item.message)
      };
    }
    else if(response.error){
      return {
        errors: response.error.message
      }
    }
    return getDeepestObject(response.data);
  }

  static omitTypename(key, value) {
    return key === '__typename' ? undefined : value
  }

  static get CLIENT() {
    return GraphQL.client;
  }
}
