import React, { useState, useEffect } from "react";

// Components
import AddTagsForm from "./AddTagsForm";
import Alert from "components/Alert/index";
import Icon from "components/Icon/";

// Services
import ImageService from "services/ImageService";

// Utils
import ROLE from "utils/enums/role";
import { getUserRoles } from "utils/helpers/getUserRoles";

import classNames, * as cn from "classnames";

const TAGS_MAPPER = {
  INT: "Number",
  STRING: "Text",
  DATETIME: "Date and time",
  ARRAY: "List",
  BOOL: "True or False",
};

const Tags = (props) => {
  const [tagsErrors, setTagsErrors] = useState([]);

  const [createErrors, setCreateErrors] = useState([]);
  const [createResponseMessage, setCreateResponseMessage] = useState("");

  const [deleteErrors, setDeleteErrors] = useState([]);
  const [deleteResponseMessage, setDeleteResponseMessage] = useState("");

  const [tags, setTags] = useState({});

  const userRoles = getUserRoles(props.user);
  const isExternalDA = userRoles
    ? userRoles.toString() === ROLE.EXTERNAL_DATA_ANALYST
    : false;

  useEffect(() => {
    getTags();
  }, [props.main.serviceType]);

  const getTags = async () => {
    const { client } = props.client;
    const { serviceType } = props.main;

    if (serviceType && serviceType.value !== "") {
      const response = await ImageService.getTags(
        client,
        serviceType.value.toLowerCase()
      );

      setTagsErrors([]);
      setCreateErrors([]);
      setDeleteErrors([]);

      if (response.errors) {
        setTagsErrors(response.errors);
      } else {
        // Nested Object destructuring
        const { fields } = response;
        if (fields) {
          fields.sort((a, b) => a.key.localeCompare(b.key));

          Object.values(fields)
            .reduce((array, item) => {
              item.key === "editor" &&
              props.user.roles &&
              props.user.roles.some((role) => {
                return role.value === ROLE.DATA_ANALYST;
              })
                ? array.splice(
                    array.findIndex((obj) => {
                      return obj.key == item.key;
                    }),
                    1
                  )
                : array.push(item);

              return array;
            }, [])
            .filter((item) => {
              return isExternalDA
                ? item.key === "productList" ||
                    item.key === "modelTestType" ||
                    item.key === "testIds" ||
                    item.key === "trolley"
                : item.key !== "_id" && item.key !== "key_1";
            })
            .map((item) => {
              if (item.stringValue) {
                setTags((tags) => ({
                  ...tags,
                  [item.key]: { name: item.key, type: item.stringValue },
                }));
              } else if (item.numberValue) {
                setTags((tags) => ({
                  ...tags,
                  [item.key]: { name: item.key, type: item.numberValue },
                }));
              } else if (item.fieldsResponse) {
                item.fieldsResponse.map((subItem) => {
                  setTags((tags) => ({
                    ...tags,
                    [subItem.key]: {
                      name: subItem.key,
                      type: subItem.numberValue,
                    },
                  }));
                });
              }
            });
        }
      }
    }
  };

  const handleOnClose = () => {
    props.close();
    document.getElementById("createTagForm") &&
      document.getElementById("createTagForm").reset();
  };

  const handleSubmit = async (tag) => {
    const { client } = props.client;
    const { serviceType } = props.main;
    if (serviceType !== "") {
      const response = await ImageService.createTags(
        client,
        [
          {
            tag: tag.name,
            type: tag.type,
          },
        ],
        serviceType.value.toLowerCase()
      );

      setCreateResponseMessage([]);
      setCreateResponseMessage("");

      if (response.errors) {
        setCreateErrors(response.errors);
      } else {
        if (response.success) {
          props.tagsChanged(true);
          setCreateResponseMessage(response.message);
          document.getElementById("createTagForm").reset();

          getTags();

          setTimeout(() => {
            setCreateResponseMessage("");
          }, 2000);
        }
      }
    }
  };

  const handleDelete = async (name) => {
    const { client } = props.client;
    const { serviceType } = props.main;
    if (serviceType !== "") {
      const response = await ImageService.deleteTag(
        client,
        name,
        serviceType.value.toLowerCase()
      );

      setDeleteResponseMessage([]);
      setDeleteResponseMessage("");

      if (response.errors !== null) {
        setDeleteErrors(response.errors);
      } else {
        props.tagsChanged(true);
        setDeleteResponseMessage(response.message);

        delete tags[name];
        getTags();

        setTimeout(() => {
          setDeleteResponseMessage("");
        }, 2000);
      }
    }
  };

  const renderTags = () => {
    return Object.values(tags).map((item) => {
      return (
        <div className="tag-item" key={item.name}>
          <div className="tag-name">
            <p>{item.name}</p>
          </div>
          <div className="tag-value">
            <p>{TAGS_MAPPER[item.type.toUpperCase()]}</p>
          </div>
          {!isExternalDA && (
            <div className="manage-tag">
              <button
                className="remove-tag"
                onClick={() => {
                  handleDelete(item.name);
                }}
              >
                <Icon name="trash-2" />
              </button>
            </div>
          )}
        </div>
      );
    });
  };

  const classes = cn("modal tags-modal noselect", { "is-visible": props.show });

  return (
    <div className={classes}>
      <div className="modal-container">
        <div className="modal-close">
          <button
            id="closeModal"
            onClick={() => {
              handleOnClose();
            }}
          >
            <i className="fe fe-x"></i>
          </button>
        </div>
        <div className="modal-content">
          <div className="row">
            <div
              className={classNames(isExternalDA ? "col-md-12" : "col-md-7")}
            >
              <div className="modal-section-title">
                <h4>Current tags:</h4>
              </div>
              {tagsErrors.length > 0 && (
                <Alert type={"danger"}>
                  {tagsErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}

              {deleteErrors.length > 0 && (
                <Alert type={"danger"}>
                  {deleteErrors.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </Alert>
              )}
              {deleteResponseMessage && (
                <Alert type={"success"}>
                  <span>{deleteResponseMessage}</span>
                </Alert>
              )}
              <div className="tag-list">{renderTags()}</div>
            </div>
            {!isExternalDA && (
              <div className="col-md-5">
                <div className="modal-section-title">
                  <h4>Create tag:</h4>
                </div>
                {createErrors.length > 0 && (
                  <Alert type={"danger"}>
                    {createErrors.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </Alert>
                )}
                {createResponseMessage && (
                  <Alert type={"success"}>
                    <span>{createResponseMessage}</span>
                  </Alert>
                )}
                <AddTagsForm onSubmit={handleSubmit} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tags;
