import { IMAGE_PROPS } from "../../consts";

function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}

const imageListFormatter = (images) => {
  const list = [];

  images.map((image) => {
    const { data } = image;
    const productIdIndex = data.findIndex(
      (item) => item.key === IMAGE_PROPS.PRODUCT_ID
    );
    const _id = data.findIndex((item) => item.key === IMAGE_PROPS.ID);
    const pathIndex = data.findIndex((item) => item.key === IMAGE_PROPS.PATH);
    const extension = data.findIndex(
      (item) => item.key === IMAGE_PROPS.EXTENSION
    );
    const fileType = data.findIndex(
      (item) => item.key === IMAGE_PROPS.FILE_TYPE
    );
    const modelTestType = data.findIndex(
      (item) => item.key === IMAGE_PROPS.MODEL_TEST_TYPE
    );
    const productList = data.findIndex(
      (item) => item.key === IMAGE_PROPS.PRODUCT_LIST
    );
    const testIds = data.findIndex((item) => item.key === IMAGE_PROPS.TEST_IDS);
    const watchedVideo = data.findIndex(
      (item) => item.key === IMAGE_PROPS.WATCHED_VIDEO
    );
    const cvFilters = data.findIndex(
      (item) => item.key === IMAGE_PROPS.CV_FILTERS
    );
    const trolley = data.findIndex((item) => item.key === IMAGE_PROPS.TROLLEY);

    if (/(gif|jpg|jpeg|tiff|png|mp4)$/i.test(data[extension].stringValue)) {
      list.push({
        productId:
          productIdIndex != -1 ? data[productIdIndex].numberValue : null,
        _id: data[_id].stringValue,
        imageUrl:
          data[fileType].stringValue && data[fileType].stringValue !== "videos"
            ? data[pathIndex].stringValue
            : undefined,
        fileType: data[fileType].stringValue,
        modelTestType:
          data[modelTestType] &&
          ((data[modelTestType].stringValue &&
            isString(data[modelTestType].stringValue)) ||
            (data[modelTestType].listValue &&
              Array.isArray(data[modelTestType].listValue) &&
              data[modelTestType].listValue.length > 0))
            ? true
            : false,
        productList:
          data[productList] &&
          data[productList].listValue &&
          data[productList].listValue.length > 0
            ? data[productList].listValue.map((item) => item)
            : [],
        testIds:
          data[testIds] &&
          data[testIds].listValue &&
          data[testIds].listValue.length > 0
            ? data[testIds].listValue.map((item) => item)
            : [],
        videoUrl:
          data[fileType].stringValue &&
          data[fileType].stringValue === "videos" &&
          data[pathIndex].stringValue
            ? data[pathIndex].stringValue
            : undefined,
        watchedVideo:
          data[watchedVideo] && data[watchedVideo].booleanValue
            ? data[watchedVideo].booleanValue
            : false,
        cvFilters:
          data[cvFilters] &&
          data[cvFilters].listValue &&
          data[cvFilters].listValue.length > 0
            ? data[cvFilters].listValue.map((item) => item)
            : [],
        trolley:
          data[trolley] && data[trolley].booleanValue
            ? data[trolley].booleanValue
            : false,
      });
    }
  });

  return list;
};

export default imageListFormatter;
