import React, { useState, useEffect } from 'react';
import Alert from '../../../../components/Alert';

import * as cn from 'classnames';

const ConfirmDeleteModal = (props) => {

    const [showDefaultMessage, setShowDefaultMessage] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');

    const confirmDelete = async () => {
        const response = await props.deleteFiles();

        if(!response.message.includes('errors')){
            setShowDefaultMessage(false);
            setResponseMessage(response.message);

            setTimeout(() => {
                handleOnClose();
            }, 1000)
       }  
        
    }

    const handleOnClose = async () => {
        props.onClose();
        setShowDefaultMessage(true);
    }

    const classes = cn('modal confirm-modal noselect', { 'is-visible': props.show });
    return (
        <div className={classes}>
            <div className="modal-container">
                <div className="modal-close">
                    <button id="closeModal" onClick={()=> { handleOnClose() }}>
                        <i className="fe fe-x"></i>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-section-title">
                        <h4>Delete images</h4>
                    </div>
                    <div className="confirmation-message">
                        { showDefaultMessage && <p>Are you sure you want to delete selected images? This action cannot be undone.</p>}
                        { !showDefaultMessage && <Alert type={"success"}>
                            <span>{ responseMessage }</span>
                        </Alert>}
                    </div>
                    { showDefaultMessage && <div className="confirmation-btn-container">
                        <div className="btn-group">
                            <button className="cancel-btn" onClick={() => { handleOnClose() }}>Cancel</button>
                            <button className="confirm-btn confirm-delete-btn" onClick={() => { confirmDelete() }}>Confirm</button>
                        </div> 
                    </div> }
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal;