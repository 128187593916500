import React, { useState, useEffect, useRef } from "react";

import history from 'store/history';

import ToastNotificationService from "../../services/ToastNotificationService";

import { capitalize } from "utils/helpers/capitalize";

import './style.css';

const MESSAGE_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARNING: 'WARNING'
}

const ToastNotification = (props) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const subscription = ToastNotificationService.onMessage(props.id).subscribe(item => {
             if (!item.message) {
                setMessages(messages => {
                    const filteredMessages = messages.filter(x => x.keepAfterRouteChange);

                    filteredMessages.forEach(x => delete x.keepAfterRouteChange);
                    return filteredMessages;
                });
            } else {
                setMessages(messages => ([...messages, item]));

                if (item.autoClose) {
                    setTimeout(() => removeMessage(item), 5000);
                }
            }
        });

        const historyUnlisten = history.listen(() => {
            ToastNotificationService.clear();
        });

        return () => {
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

    const removeMessage = (message) => {
        setMessages(messages => messages.filter(x => x !== message));
    }

    const generateClasses = (message) => {
        if (!message) return;

        const classes = ['toast-notification-item'];

        const TYPE_CLASSES = {
            [MESSAGE_TYPE.SUCCESS]: 'success',
            [MESSAGE_TYPE.ERROR]: 'danger',
            [MESSAGE_TYPE.INFO]: 'info',
            [MESSAGE_TYPE.WARNING]: 'warning'
        }

        classes.push(TYPE_CLASSES[message.type]);

        if (message.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }

    return (
        <div className="toast-notification-container">
            {messages.map((item, index) =>
                <div key={index} className={generateClasses(item)}>
                    <div className="toast-icon"></div>
                    <div className="toast-content">
                        <p className="toast-type">{capitalize(item.type)} - {capitalize(item.subject)}</p>
                        <p className="toast-message">{item.message}</p>
                    </div>
                    <div className="toast-close">
                        <a className="close" onClick={() => removeMessage(item)}>&times;</a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ToastNotification;